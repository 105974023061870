import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";

@customElement("se-card-buttons")
export class SeCardButtonsElement extends LitElement {
    render() {
        return html`
            <div class="action-panel">
                <slot></slot>
            </div>
        `;
    }

    static styles = css`
        .action-panel {
            display: flex;
            flex-direction: row;
            justify-content: right;
            margin-top: 4px;
            max-width: 1000px;
        }
    `;
} 