import { LitElement, html, css } from 'lit';
import { customElement, state, property } from 'lit/decorators.js';
import { Router } from "@vaadin/router";

@customElement('se-nav')
export class SeNav extends LitElement {
  @property({ type: String }) baseUrl = '';
  @property({ type: Array }) menuItems = [];
  @state() private _currentPath = '';
  @state() private _currentItem = null;
  @property({ type: String }) titleOverride = '';

  connectedCallback() {
      super.connectedCallback();
      this._updateCurrentPath();
      window.addEventListener('popstate', () => this._updateCurrentPath());
  }

  disconnectedCallback() {
      super.disconnectedCallback();
      window.removeEventListener('popstate', () => this._updateCurrentPath());
  }

  private _updateCurrentPath() {
      const fullPath = window.location.pathname;
      const baseUrlPattern = new RegExp(`^${this.baseUrl}/`);
      this._currentPath = fullPath.replace(baseUrlPattern, '').split('/')[0];
      this._currentItem = this.menuItems.find(item => item.path === this._currentPath) || this.menuItems[0];
  }

  static get properties() {
    return {
      baseUrl: { type: String },
      menuItems: { type: Array }
    };
  }

  static styles = css`
    :host {
      display: flex;
      box-sizing: border-box;
      font: var(--font);
      height: 100%;
    }
    h2 {
      font: var(--font-h2);
      font-weight: 600;
      margin: 0px 0px 10px;
    }
    .nav-list {
      display: flex;
      flex-direction: column;
      background-color: var(--color-blue-25);
      border-right: solid 1px var(--color-gray-2);
      padding: 15px 10px;
    }
    .nav-item {
      cursor: pointer;
      padding: 5px 10px;
      position: relative;
      color: var(--color-navy-3);
      text-wrap: nowrap;
    }
    .active-nav-item {
      color: var(--color-purple);
    }
    .active-nav-item::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      border-radius: 9px;
      background-color: var(--color-purple);
    }
    .nav-item:hover {
      background-color: rgb(49 54 93 / 9%);
      border-radius: 8px;
    }
    .container-content {
      overflow-x: hidden;
      overflow-y: auto;
      flex-grow: 1;
      padding: 15px 15px 55px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    ::slotted(*) {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  `;

  render() {
    return html`
        <div class="nav-list">
            ${this.menuItems.map(item => html`
                <div
                    @click=${() => this._handleNavClick(item.path)}
                    class="nav-item ${this._currentPath === item.path ? 'active-nav-item' : ''}"
                >
                    ${item.label}
                </div>
            `)}
        </div>
        <div class="container-content">
            <h2>${this.titleOverride || this._currentItem?.label || ''}</h2>
            <slot></slot>
        </div>
    `;
  }

    private _handleNavClick(path: string) {
        Router.go(`${this.baseUrl}/${path}`);
        this._currentPath = path;
        this._currentItem = this.menuItems.find(item => item.path === path);
    }
}


// Usage example in HTML
// <se-nav>
//   <div slot="URLslug1" label="Nav 1">Content for Nav 1</div>
//   <div slot="URLslug2" label="Nav 2">Content for Nav 2</div>
// </se-nav>