import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

@customElement("se-edit-form-card")
export class SeEditFormCardElement extends LitElement {
    @property({ type: String }) title = "";

    render() {
        return html`
            <form class="editor">
                <div class="content-container">
                    <div class="h3">${this.title}</div>
                    <div class="scroll-container">
                        <slot></slot>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
        }
        .h3 {
            font: var(--font-h3);
            background: var(--color-light);
            padding: 10px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1000px;
            min-height: 0;
            overflow: hidden;
        }
        .content-container {
            border: 1px solid var(--color-gray-4);
            border-radius: 5px;
            overflow: hidden;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border: 1px 0px 1px 0px solid var(--color-gray-4);
            box-shadow: 2px 2px 2px lightGray;
        }
    `;
} 