import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-primary-button")
export class SePrimaryButton extends LitElement {
    @property() icon: string;
    @property() iconColor: string;
    @property() title: string;
    @property() text: string;
    @property({ attribute: "action-delay", type: Number }) actionDelay = 0;
    @property({ attribute: "action-title" }) actionTitle: string;
    @property({ attribute: "action-text" }) actionText: string;
    @property({ attribute: "stop-action-text" }) stopActionText: string;
    @property({ type: Boolean, attribute: "can-stop" }) canStop = false;
    @property({ type: Object }) customStyle: object;
    @property({ type: Boolean }) disabled = false;
    @property({ attribute: "size" }) size: "small" | "normal" = "small";
    @property({ attribute: "position" }) position: "first" | "right" = "right";
    @property({ attribute: "min-sizing" }) minSizing: "auto" | "growth" = "auto";
    @property({ type: Object }) action?: (isStop?: boolean) => Promise<boolean>;
    @property() loading = false;
    @property() red = false;

    @state() private _isAction = false;
    @state() private _isActionDisplay = false;
    @state() private _isStopAction = false;
    @state() private _isActionDisabled = false;

    @state() private _minWidth?: string;

    @query("button") private _buttonElement: HTMLButtonElement;

    connectedCallback() {
        super.connectedCallback();
        addEventListener("pageshow", () => this.reset());
    }

    disconnectedCallback() {
        this.removeEventListener("pageshow", () => this.reset());
        super.disconnectedCallback();
    }

    private reset() {
        this._isAction = false;
        this._isActionDisplay = false;
        this._isStopAction = false;
        this._isActionDisabled = false;
    }

    async onClick() {
        if (this.action) {
            if (this._isAction) this._isStopAction = true;
            else {
                this._isAction = true;
                this._minWidth = this.minSizing === "auto" ? undefined : this._buttonElement.offsetWidth + "px";
            }
            this._isActionDisabled = !this.canStop || this.actionDelay > 0 || this._isStopAction;
            await this.updateComplete;
            const timeout =
                this.actionDelay > 0
                    ? setTimeout(() => {
                          this._isActionDisplay = true;
                          this._isActionDisabled = !this.canStop || this._isStopAction;
                      }, this.actionDelay)
                    : undefined;
            if (!(await this.action(this._isStopAction))) {
                //action returns true if the website has redirected to a new page or false if stayed on the current page.
                this._isAction = false;
                this._isStopAction = false;
                this._isActionDisabled = false;
                this._isActionDisplay = false;
            }
            if (timeout) {
                clearTimeout(timeout);
            }
        } else {
            const event = new Event("buttonclick", { bubbles: true, composed: true });
            this.dispatchEvent(event);
        }
    }

    render() {
        const disabled = this.disabled || this.loading || this._isActionDisabled;
        const isAction = this.loading || this._isAction || this._isActionDisplay;
        const isStopAction = this._isStopAction;
        const title = isAction
            ? isStopAction
                ? "Please wait..."
                : this.actionTitle ?? this.title
            : this.title;
        const style = {
            ...this.customStyle,
            marginLeft: this.position === "first" ? "0" : "5px",
            minWidth: this._minWidth,
        };
        const buttonClass = this.size === "normal" ? "button-normal" : "button";
        const iconClass = isAction
            ? this.canStop
                ? "fad fa-stop"
                : "far fa-spinner fa-spin"
            : this.icon;
        const iconColor = this.canStop ? "crimson" : this.iconColor;

        const buttonInnerHtml = this.loading
            ? html`
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="loading-icon"
            >
                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
            Please wait...
        `
            : html`
            ${this.icon
                    ? html`<fa-icon
                      style="font-size: 0.9em"
                      fa-class="${this.icon}"
                  ></fa-icon>&nbsp;&nbsp;`
                    : ""}
            ${this.text}
        `;

        return html`
        <button
            ?disabled=${this.disabled || this.loading || this._isActionDisabled}
            @click="${this.onClick}"
            class="${buttonClass}"
            type="button"
            title="${title}"
            style=${styleMap(style)}
            ?red="${this.red}"
        >
            ${buttonInnerHtml}
        </button>
    `;
    }


    static styles = css`
        :host([hidden]) {
            display: none;
        }
        .button {
            padding: 5px 11px;
            border-radius: 3px 3px;
            transition: all 0.25s;
            font: var(--font-button);
            background-color: var(--color-secondary);
            border: 1px solid var(--color-secondary-shade);
            color: white;
            user-select: none;
            cursor: pointer;
        }
        .button-normal {
            padding: 10px 40px;
            border-radius: 4px;
            justify-content: center;
            width: 100%;
            font: var(--font-button);
            background-color: var(--color-purple);
            color: white;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            border: 1px solid var(--color-purple);
            transition: all 0.3s ease 0s;
            text-wrap: nowrap;
        }
        button:disabled {
            opacity: 0.7;
            background-color: var(--color-secondary);
            border: 1px solid gray;
            color: lightgray;
            transition: none;
            cursor: not-allowed;
            pointer-events: none;
        }
        .button:hover {
            background-color: var(--color-secondary);
            border: 1px solid var(--color-secondary);
            box-shadow: 2px 2px 2px DarkGray;
        }
        .button:hover:disabled {
            background-color: var(--color-secondary);
            border: 1px solid gray;
            box-shadow: none;
        }
        .button:active {
            box-shadow: none;
        }
        .loading-icon {
        margin-right: 8px;
        height: 16px;
        width: 16px;
        animation: spin 1s linear infinite;
        }
        @keyframes spin {
            from {
                transform: rotate(0deg);
            }
            to {
                transform: rotate(360deg);
            }
        }
    `;
}
