import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { RunStatus } from "../../enums/run-status";
import { RunViewModel } from "../../models/run-view-model";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { RunHistoryService } from "../../services/run-history.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "./run-history-details.element";
import { ParallelExport } from "../../enums/parallel-export";
import { formatSize } from "se-shared/utils/utils";

@customElement("se-run-history")
export class SeRunHistoryElement extends LitElement {
    @property() configId;

    private _modalService: ModalDialogService;
    private _runHistoryService: RunHistoryService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _data: RunViewModel[];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn = "";
    private _sortOrder = 1;
    private _menuService: MenuService;
    private _runRefresh = true;

    @state() private _hasSelectedRows = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    constructor() {
        super();
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._runHistoryService = container.resolve(RunHistoryService);
        this._menuService = container.resolve(MenuService);
    }

    private menu(row: RunViewModel, col: DataGridColumn): MenuItem[] {
        return [{ text: "Delete", action: this.deleteRunAsync.bind(this, row, col) }];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._runRefresh = true;
        this.loadData();
    }
    disconnectedCallback() {
        this._runRefresh = false;
        super.disconnectedCallback();
    }

    private async deleteRunAsync(row: RunViewModel) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Run History",
            body: `Are you sure you want to delete run history with ${
                row.parallelism > 1 ? `parallel set ${row.parallelSet} and sequence ${row.sequence}` : `sequence ${row.sequence}`
            }?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._runHistoryService.api.deleteAsync(this.configId, row.id);
            if (result.isOk) {
                const index = this._data.indexOf(row);
                this._data.splice(index, 1);
                if (row["selected"]) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
                setTimeout(() => this.updateDataAsync(), 10000);
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async updateDataAsync() {
        if (!this._runRefresh) {
            return;
        }
        if (!this._hasSelectedRows && !this._menuService.isMenuOpen()) {
            const result = await this._runHistoryService.api.getManyAsync(
                this.configId,
                this._pageIndex,
                this._recordsPerPage,
                this._sortColumn,
                this._sortOrder
            );
            if (result.isOk) {
                this._data = result.value.runs;
                if (this._data.some((s) => s.endTime)) {
                    this._columns.find((p) => p.field === "endTime").hidden = false;
                } else {
                    this._columns.find((p) => p.field === "endTime").hidden = true;
                }
            }
        }
        setTimeout(() => this.updateDataAsync(), 10000);
    }

    private getParallelism(row: RunViewModel): TemplateResult {
        return html`<span
            ${htmlTitle(
                html`<table>
                    <tr>
                        <td>Total runs:</td>
                        <td>${row.parallelism}</td>
                    </tr>
                    <tr>
                        <td>Concurrency:</td>
                        <td>${row.parallelMaxConcurrency ?? "Unlimited"}</td>
                    </tr>
                    <tr>
                        <td>Export:</td>
                        <td>${row.parallelExport ?? ParallelExport.Combined}</td>
                    </tr>
                </table>`,
                true
            )}
            style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
            >${row.parallelism}</span
        >`;
    }

    private getStatus(color: string, text: string, row: RunViewModel): TemplateResult {
        if (row.message) {
            return html`<span
                ${htmlTitle(row.message, true)}
                style="border-bottom: 1px dotted;color:${color};cursor:pointer;user-select: none;"
                >${text}</span
            >`;
        } else {
            return html`<span style="color:${color};">${text}</span>`;
        }
    }

    private async loadData(isShowLoading = true) {
        this._columns = [
            { field: "sequence", title: "#", align: "center" },
            {
                field: "parallelism",
                title: "Parallelism",
                align: "center",
                hidden: true,
                template: (row) =>
                    row.parallelSet
                        ? html`${row.parallelSet}/${row.parallelism}`
                        : (row.parallelism ?? 1) > 1
                          ? this.getParallelism(row)
                          : html``,
            },
            { field: "configVersion", title: "Version", align: "center" },
            {
                field: "status",
                title: "Status",
                sortable: true,
                align: "center",
                template: (row) =>
                    html`<div style="width: 100%">${choose(
                        row.status,
                        [
                            [
                                undefined || null,
                                () =>
                                    html`<se-status status-message="No Activity" status-color="--color-gray-4"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.success,
                                () =>
                                    html`<se-status status-message="Success" status-color="--color-status-blue"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.failure,
                                () =>
                                    html`<se-status status-message="Failed" status-color="--color-status-red"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.failed,
                                () =>
                                    html`<se-status status-message="Failed" status-color="--color-status-red"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.completed,
                                () =>
                                    html`<se-status status-message="Completed" status-color="--color-status-blue"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.running,
                                () =>
                                    html`<se-status status-message="Running" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.exporting,
                                () =>
                                    html`<se-status status-message="Exporting" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.starting,
                                () =>
                                    html`<se-status status-message="Starting" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.stopped,
                                () =>
                                    html`<se-status status-message="Stopped" status-color="--color-gray-4"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.stopping,
                                () =>
                                    html`<se-status status-message="Stopping" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                            ],
                            [
                                RunStatus.queuing,
                                () =>
                                    html`<se-status status-message="Queuing" status-color="--color-gray-4"
                                                      ></se-status>`,
                            ],
                            [
                                RunStatus.waiting,
                                () => html`<se-status status-message="Waiting" status-color="--color-teal"
                                                      ></se-status>`,
                            ],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "startTime",
                title: "Started",
                align: "center",
                sortable: true,
                template: (row) => {
                    const date = row.startTime ? new Date(row.startTime) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },
            {
                field: "endTime",
                title: "Completed",
                align: "center",
                sortable: true,
                template: (row) => {
                    const date = row.startTime && row.endTime ? new Date(row.endTime) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },
            { field: "actionCount", title: "Actions", align: "center" },
            { field: "dataCount", title: "Data", align: "center" },
            { field: "errorCount", title: "Errors", align: "center" },
            { field: "pageCount", title: "Pages", align: "center" },
            { field: "dynamicPageCount", title: "Dynamic", align: "center" },
            { title: "Rate", align: "center", template: (row) => html`${this.getRate(row)}` },
            { field: "requestCount", title: "Requests", align: "center" },
            {
                field: "inputCount",
                title: "Inputs",
                align: "center",
                hidden: true,
                template: (row) => html`${row.inputCount ?? 0}`,
            },
            { field: "traffic", title: "Traffic", align: "center", template: (row) => html`${formatSize(row.traffic)}` },
            {
                // View details column
                name: "info",
                cellStyle: { textAlign: "center", width: "20px" },
                template: (row) => {
                    const tooltip = "View run details and files";
                    return html`<se-secondary-button
                        title="${tooltip}"
                        icon="far fa-browser"
                        @click=${() => this.runHistoryDetails(row.id)}
                    ></se-secondary-button>`;
                },
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];

        if (isShowLoading) this._isLoading = true;
        try {
            const result = await this._runHistoryService.api.getManyAsync(
                this.configId,
                this._pageIndex,
                this._recordsPerPage,
                this._sortColumn,
                this._sortOrder
            );
            if (result.isOk) {
                this._data = result.value.runs;
                this._totalRecordCount = result.value.totalRecordCount;
                this._columns.find((p) => p.field === "parallelism").hidden = !this._data.some((p) => p.parallelism && p.parallelism > 1);
                this._columns.find((p) => p.field === "inputCount").hidden = !this._data.some((p) => p.inputCount);

                setTimeout(() => this.updateDataAsync(), 10000);
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
        if (this._selectAll) this._selectAll.value = false;
    }

    private getRate(row) {
        /*const date = new Date(row.startTime);
        const now = new Date(row.endTime);
        return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);*/
        const seconds = row.runTimeSec ?? 0;
        return seconds ? (row.pageCount / seconds).toFixed(2) : "n/a";
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== (this._data?.length ?? 0)) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        if (this._dataGrid.selectedRows.some((p) => !RunViewModel.canDelete(p.status))) {
            this._toasterService.showError("You cannot delete active runs. Please stop the runs first.");
            return;
        }

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Run History",
            body: `Are you sure you want to delete ${count === 1 ? `1 run` : `${count} runs`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const ids = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._runHistoryService.api.deleteManyAsync(this.configId, ids);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async onRerunMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Stop Runs",
            body: `Are you sure you want to stop ${count === 1 ? `1 run` : `${count} runs`}?`,
            saveCaption: "Stop Runs",
        });
        if (result.isSave) {
            const serverIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._runHistoryService.api.rerunMany(this.configId, serverIds);
            if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
            this.loadData();
        }
    }

    private async runHistoryDetails(runId: number) {
        this.dispatchEvent(new CustomEvent("showRunHistoryDetails", { bubbles: true, composed: true, detail: { runId: runId } }));
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        const canStop = this._hasSelectedRows && this._dataGrid.selectedRows.some((p) => RunViewModel.canStop(p));
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${!this._data || this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete selected runs")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                                  ${when(
                                      canStop,
                                      () =>
                                          html`<se-secondary-button
                                              @mousedown=${(event) => this.onRerunMany(event)}
                                              ${htmlTitle("Rerun selected")}
                                              .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                              icon="fas fa-square"
                                              iconColor="dimgray"
                                          ></se-secondary-button>`
                                  )}
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No runs available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
                <se-pagination .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
