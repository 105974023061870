import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import "element-internals-polyfill";
import { BaseEditor } from "../base-editor";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-form-datetime-editor")
export class FormDateTimeEditorElement extends LitElement implements BaseEditor {
    @property({ type: Object }) value: Date;
    get liveValue() {
        if (this.type === "datetime-local") return this._inputElement?.value ? new Date(this._inputElement.value) : undefined;
        else return this._inputElement?.valueAsDate;
    }

    @property() label?: string;
    @property() labelHelp?: string;

    @property() min?: string;
    @property() max?: string;
    @property() placeholder?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property() type: "date" | "datetime-local" | "time" = "date";
    @property({ type: Boolean, attribute: "use-auto-complete" }) useAutoComplete = false;
    @query("input") private _inputElement: HTMLInputElement;

    constructor() {
        super();
    }

    valueChanged() {
        this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }
    updateValue(event) {
        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    firstUpdated() {}

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        let d: string;
        if (this.type === "date")
            d = `${this.value.getFullYear()}-${String(this.value.getMonth() + 1).padStart(2, "0")}-${String(this.value.getDate()).padStart(2, "0")}`;
        else if (this.type === "time")
            d = `${String(this.value.getHours()).padStart(2, "0")}:${String(this.value.getMinutes()).padStart(2, "0")}:${String(this.value.getSeconds()).padStart(2, "0")}`;
        else if (this.type === "datetime-local")
            d = `${this.value.getFullYear()}-${String(this.value.getMonth() + 1).padStart(2, "0")}-${String(this.value.getDate()).padStart(2, "0")} ${String(this.value.getHours()).padStart(2, "0")}:${String(this.value.getMinutes()).padStart(2, "0")}:${String(this.value.getSeconds()).padStart(2, "0")}`;

        return html`
            <div class="form-row">
                <label for="${this.name}">
                    <div><span>${this.label}</span></div>
                    <div class="help">${this.labelHelp}</div>
                </label>
                <div class="input-container">
                    <input
                        class="input-settings"
                        name="${this.name}"
                        type="${this.type}"
                        value=${d}
                        @input="${this.updateValue}"
                        @change="${this.valueChanged}"
                        min="${ifDefined(this.min)}"
                        max="${ifDefined(this.max)}"
                        placeholder="${ifDefined(this.placeholder)}"
                        ?required="${this.required}"
                        ?disabkled="${this.disabled}"
                        autocomplete=${this.useAutoComplete ? "on" : "off"}
                    />
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
        }
        .form-row {
            padding: 10px;
            align-items: center;
            display: flex;
            transition: background 0.15s;
            border-bottom: 1px solid rgb(240, 236, 243);
        }
        input {
            font: var(--font-input);
        }
        input:disabled + label {
            color: gray;
        }
        input.invalid {
            outline: 2px solid pink;
        }
        .form-row {
            padding: 10px;
            align-items: center;
            display: flex;
            transition: background 0.15s;
            border-bottom: 1px solid rgb(240, 236, 243);
        }
        .form-row label {
            font-weight: 400;
            display: inline;
            margin-bottom: 0px;
            width: 50%;
            flex-shrink: 0;
        }
        .input-container {
            display: flex;
            flex: 1 1 0%;
            flex-direction: column;
            position: relative;
            max-width: 100%;
        }
        .input-settings {
            font: var(--font-input);
            border-radius: 8px;
            border: 1px solid var(--color-gray-4);
            padding: 8px 12px;
            background: var(--color-background);
            width: 13em;
        }
        label {
            font: var(--font-input-label);
            margin-bottom: 4px;
        }

        label .help {
            color: rgb(128, 112, 143);
            font-size: 12px;
            margin-top: 4px;
            line-height: 1.4;
        }
    `;
}
