import { ok } from "se-shared/utils/result";
import { container, singleton } from "tsyringe";
import { integer } from "vscode-languageserver-protocol";
import { AccessLevel } from "../enums/access-level";
import { LoginToken } from "../models/login";
import { UserAccess } from "../models/user-access";
import { AuthApi } from "./auth.api";
import { UserApi } from "./user-api";
import { AppConfigService } from "../services/app-config.service";

@singleton()
export class AuthService {
    private _authApi: AuthApi;
    private _userApi: UserApi;
    private _user: UserAccess;
    private _token: string;
    private _expiry: Date;
    private _isLoggedIn: boolean;

    private _isOrgAdmin: boolean;
    private _isSE4Admin: boolean;
    private _isUser: boolean;
    private _isReadOnly: boolean;

    orgSettings = { isAuditLog: false, isProxyRequired: true, isAllowDevTools: false };

    get token() {
        return this._token ?? "";
    }
    get user() {
        return this._user;
    }
    get isSE4Admin() {
        return this._isSE4Admin;
    }
    get isOrgAdmin() {
        return this._isLoggedIn && this._isOrgAdmin;
    }
    get isUser() {
        return this._isLoggedIn && this._isUser;
    }
    get isLoggedIn() {
        return this._isLoggedIn;
    }

    constructor() {
        this._authApi = container.resolve(AuthApi);
        this.loadUser();
    }

    async loginAsync(email: string, password: string, isKeepMeLoggedIn?: boolean) {
        const res = await this._authApi.loginAsync(email, password, isKeepMeLoggedIn);
        if (res.isOk) {
            this.login(res.value, isKeepMeLoggedIn);
            return res;
        } else {
            throw res.err;
        }
    }

    async loginWithGoogleIdToken(idToken: string) {
        const res = await this._authApi.loginWithGoogleIdToken(idToken);
        if (res.isOk) {
            this.login(res.value, false);
            return res;
        } else {
            throw res.err;
        }
    }

    async loginWithazureIdToken(idToken: string) {
        const res = await this._authApi.loginWithAzureIdToken(idToken);
        if (res.isOk) {
            this.login(res.value, false);
            return res;
        } else {
            throw res.err;
        }
    }

    async RecoverPassword(email: string) {
        const res = await this._authApi.RecoverPassword(email);
        //const res = await this._authApi.RecoverPassword(email, emailTemplate);
        if (res.isOk) {
            return ok({ message: "Recovery email sent" });
        } else {
            throw res.err;
            //alert(res.err.message);
        }
    }

    //async ResetPassword(email: string, token: string, password: string) {
    async ResetPassword(token: string, password: string) {
        this._userApi = container.resolve(UserApi);
        const res = await this._userApi.ResetPassword(token, password);
        if (res.isOk) {
            return ok({ message: "Password changed succesfully." });
        } else {
            throw res.err;
            //alert(res.err.message);
        }
    }

    //async ChangePassword(userId: integer, password: string) {
    async ChangePassword(currentPassword: string, newPassword: string) {
        this._userApi = container.resolve(UserApi);
        const res = await this._userApi.ChangePassword(currentPassword, newPassword);
        if (res.isOk) {
            return ok({ message: "Password changed succesfully." });
        } else {
            throw res.err;
            //alert(res.err.message);
        }
    }

    async ValidateToken(userId: string, token: string) {
        const res = await this._authApi.ValidateToken(userId, token);
        return res;
    //    if (res.isOk) {
    //        return res;
    //    } else {
    //        throw res.err;
    //    }
    }

    async login(login: LoginToken, isKeepMeLoggedIn?: boolean) {
        this._user = login.userAccess;
        this._expiry = login.expiry;
        this._token = login.token;
        this._isLoggedIn = true;
        this.setSystemAccess();

        if (isKeepMeLoggedIn) {
            localStorage.setItem("login", JSON.stringify(login));
        } else {
            sessionStorage.setItem("login", JSON.stringify(login));
        }

        const appConfigService = container.resolve(AppConfigService);
        appConfigService.isShowWelcome = login.userAccess.isShowWelcome;

        const result = await this._authApi.getOrgSettings(login.token);
        if (result.isOk) this.orgSettings = result.value;
    }
    logout() {
        this._user = null;
        this._expiry = null;
        this._token = "";
        this._isLoggedIn = false;
        localStorage.removeItem("login");
        sessionStorage.removeItem("login");
    }

    private async loadUser() {
        let loginJson = localStorage.getItem("login");
        if (!loginJson) {
            loginJson = sessionStorage.getItem("login");
            if (!loginJson) {
                this._isLoggedIn = false;
                return;
            }
        }

        const login = JSON.parse(loginJson) as LoginToken;
        this._expiry = login.expiry;
        this._token = login.token;
        this._isLoggedIn = !this.hasTokenExpired();
        if (this._isLoggedIn) {
            this._user = login.userAccess;
            this.setSystemAccess();

            try {
                const result = await this._authApi.getOrgSettings(login.token);
                if (result.isOk) this.orgSettings = result.value;
            } catch (err) {
                console.error(err);
            }
        }
    }

    changeOrganization(orgId: number, orgName: string) {
        this.user.organizationId = orgId;
        this.user.organizationName = orgName;

        let loginJson = localStorage.getItem("login");
        if (loginJson) {
            const login = JSON.parse(loginJson) as LoginToken;
            login.userAccess.organizationId = orgId;
            login.userAccess.organizationName = orgName;
            localStorage.setItem("login", JSON.stringify(login));
        } else {
            loginJson = sessionStorage.getItem("login");
            if (loginJson) {
                const login = JSON.parse(loginJson) as LoginToken;
                login.userAccess.organizationId = orgId;
                login.userAccess.organizationName = orgName;
                sessionStorage.setItem("login", JSON.stringify(login));
            }
        }
    }

    private setSystemAccess() {
        this._isSE4Admin = this._user && this._user.accessLevel <= AccessLevel.SE4Administrator;
        this._isOrgAdmin = this._user && this._user.accessLevel <= AccessLevel.OrgAdministrator;
        this._isUser = this._user && this._user.accessLevel <= AccessLevel.User;
        this._isReadOnly = this._user && this._user.accessLevel <= AccessLevel.ReadOnly;
    }

    private hasTokenExpired() {
        return !this._expiry || this._expiry.valueOf() < new Date().valueOf();
    }
}
