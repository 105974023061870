import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ApiKey } from "../../models/api-key";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { UserService } from "../../services/user.service";
import { SeApiKeyEditorFormElement } from "../components/api-keys/api-key-editor-form.element";

@customElement("se-api-key-editor")
export class SeApiKeyEditorElement extends LitElement {
    private _authService: AuthService;
    private _modalService: ModalDialogService;
    private _isNew = false;
    private _apiKeyId?: number;
    private _userId?: number;
    private _apiKey?: ApiKey;
    private _userService: UserService;
    private _toasterService: ToasterService;

    @query("se-api-key-editor-form") private _form: SeApiKeyEditorFormElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userService = container.resolve(UserService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
    }

    /**
     * Lifecycle hook called before entering the route.
     * Validates admin access and loads API key data if editing an existing key.
     * @param location - Router location information
     * @param commands - Navigation commands
     * @param router - Router instance
     * @returns Redirect command if unauthorized or prevention if invalid params
     */
    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.apiKeyId) {
            this._apiKeyId = parseInt(location.params.apiKeyId.valueOf() as string);
            this._userId = parseInt(location.params.userId.valueOf() as string);
            this._isNew = false;
            const res = await this._userService.api.getApiKeyAsync(this._userId, this._apiKeyId);
            if (res.isOk) {
                this._apiKey = res.value;
            } else {
                return commands.prevent();
            }
        } else if (location.params.userId) {
            this._userId = parseInt(location.params.userId.valueOf() as string);
            this._isNew = true;
            this._apiKey = new ApiKey();
            const now = new Date();
            const d = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
            this._apiKey.expiry = d.toISOString();
        } else {
            return commands.prevent();
        }
    }

    /**
     * Handles the save operation for both new and existing API keys
     * @param description - The API key description
     * @param expiry - The API key expiration date
     */
    private async handleSave(description: string, expiry: string) {
        if (this._isNew) {
            await this.createNewApiKey(description, expiry);
        } else {
            await this.updateApiKey(description, expiry);
        }
    }

    /**
     * Creates a new API key and displays the token in a modal dialog
     * @param description - The API key description
     * @param expiry - The API key expiration date
     */
    async createNewApiKey(description: string, expiry: string) {
        const res = await this._userService.api.createApiKeyAsync(this._userId, {
            userId: this._userId,
            description,
            expiry,
        });

        if (res.isOk) {
            const result = await this._modalService.openInfoDialogAsync({
                title: "Save Api Key",
                body: `Please copy the key to a secure location because this will be the only time that it is visible.
                  <p>
                    <button
                        type="button"
                        onclick="navigator.clipboard.writeText('${res.value.token}')"
                        title="Copy to Clipboard"
                        icon="far fa-copy"
                        class="copy-button"
                    >
                    <fa-icon
                              style="font-size:0.9em"
                              fa-class="far fa-copy"
                              single-color=""
                              scale="0.9"
                          ></fa-icon>
                    </button>
                    <strong><span id="copyValue">${res.value.token}</span></strong>
                  </p>`,
            });
            if (result.isSave) {
                history.back();
            }
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    /**
     * Updates an existing API key's description and expiry
     * @param description - The API key description
     * @param expiry - The API key expiration date
     */
    async updateApiKey(description: string, expiry: string) {
        const res = await this._userService.api.updateApiKeyAsync(this._userId, this._apiKeyId, {
            id: this._apiKeyId,
            userId: this._apiKey.userId,
            description,
            expiry,
        });

        if (res.isOk) {
            history.back();
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    /**
     * Renders the API key editor form component
     * @returns Lit HTML template
     */
    render() {
        return html`
            <se-api-key-editor-form
                .isNew=${this._isNew}
                .apiKey=${this._apiKey}
                .onSave=${(description: string, expiry: string) => this.handleSave(description, expiry)}
                .onCancel=${() => history.back()}
            ></se-api-key-editor-form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
}
