import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { Organization } from "../../models/organization";
import { UserGroup } from "../../models/user-group";
import { AuthService } from "../../services/auth.service";
import { UserGroupService } from "../../services/user-group.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { BaseEditor } from "../editors/base-editor";
import { InputEditorElement } from "../editors/input-editor.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";

@customElement("se-user-group-editor")
export class SeUserGroupEditorElement extends LitElement {
    private _authService: AuthService;
    private _userGroupService: UserGroupService;
    private _isNew = false;
    private _userGroupId?: number;
    private _userGroup: UserGroup;
    private _orgs: Organization[];
    private _recordsPerPage = 7;
    private _pageIndex = 1;
    private _toasterService: ToasterService;

    private _userState: UserState;
    private _totalRecordCount: number;
    private _sortColumn: string;
    private _sortOrder = -1;
    private _columns: DataGridColumn[] = [];

    @state() private _data: any[] = [];
    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#description") private _descriptionEditor: TextAreaEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userGroupService = container.resolve(UserGroupService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.userGroupId) {
            this._columns = [
                { field: "name", title: "Name", sortable: true },
                { field: "email", title: "Email", sortable: true },
            ];

            this._userGroupId = parseInt(location.params.userGroupId.valueOf() as string);
            this._isNew = false;
            //return this._spaceApi.getAsync(this._spaceId).then(space => this._space = space).catch(err => this.error(err));
            const res = await this._userGroupService.api.getAsync(this._userGroupId);
            await this.loadData();
            //const resUsers = await this._userGroupService.api.getGroupUsersAsync(this._userGroupId);
            if (res.isOk) {
                this._userGroup = res.value;
                this._totalRecordCount = res.value.totalRecordCount;
                //    resUsers.value.map(e => e.selected = (e.isInGroup !== null));
                //    this._data = resUsers.value;
            } else {
                alert(res.err.message);
                return commands.prevent();
            }
        } else {
            this._userGroupId = -1;
            this._isNew = true;
            this._userGroup = new UserGroup();
            this._columns = [
                { field: "name", title: "Name", sortable: true },
                { field: "email", title: "Email", sortable: true },
            ];

            const res = await this._userGroupService.api.getAsync(this._userGroupId);
            await this.loadData();
            //const resUsers = await this._userGroupService.api.getGroupUsersAsync(this._userGroupId);
            if (res.isOk) {
                this._userGroup = res.value;
                this._totalRecordCount = res.value.totalRecordCount;
                //    resUsers.value.map(e => e.selected = (e.isInGroup !== null));
                //    this._data = resUsers.value;
            } else {
                alert(res.err.message);
                return commands.prevent();
            }
        }
    }
    private async loadData() {
        this._columns = [
            { field: "name", title: "Name", sortable: true },
            { field: "email", title: "Email", sortable: true },
        ];

        const resUsers = await this._userGroupService.api.getGroupUsersAsync(
            this._userGroupId,
            this._pageIndex,
            this._recordsPerPage,
            this._sortColumn,
            this._sortOrder
        );
        if (resUsers.isOk) {
            resUsers.value.map((e) => (e.selected = e.isInGroup !== null));
            this._data = resUsers.value;
        } else {
            alert(resUsers.err.message);
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const selectedData = this._data.filter((e) => e.selected == true);
            const selectedIds = selectedData.map((e) => e.id);
            const res = this._isNew
                ? await this._userGroupService.api.createAsync(this._nameEditor.liveValue, this._descriptionEditor.liveValue, selectedIds)
                : await this._userGroupService.api.updateAsync(
                      this._userGroupId,
                      this._nameEditor.liveValue,
                      this._descriptionEditor.liveValue,
                      selectedIds
                  );
            if (res.isOk) {
                history.back();
            } else {
                alert(res.err.message);
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = "ub." + sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._hasSelectedRows = true;
        } else {
            this._hasSelectedRows = true;
        }
    }
    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Add" : "Edit"} User Group</div>
                <div class="scroll-container">
                    <se-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="40"
                        .value=${this._userGroup.name}
                    ></se-input-editor>
                    <se-textarea-editor
                        id="description"
                        name="description"
                        type="text"
                        label="Description"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._userGroup.description ?? ""}
                    ></se-textarea-editor>
                    <se-data-grid
                        class="grid"
                        .rows=${this._data}
                        .recordsPerPage=${this._recordsPerPage}
                        .pageIndex=${this._pageIndex}
                        .columns=${this._columns}
                        selectAllCheckbox
                        hasPreSelected
                        selectable
                        @selectionchanged=${this.onGridSelectionChanged}
                        @sortdata=${this.sortDataGrid}
                    ></se-data-grid>
                        <se-pagination
                            .recordCount=${this._totalRecordCount}
                            recordsPerPage="7"
                            @pagechanged=${this.onPageChanged}
                        ></se-pagination>
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Add" : "Save"} Group"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
