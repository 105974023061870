import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { AppSettings } from "../../models/app-settings";
import "../components/side-nav.element"
import { SeUserEditorElement } from "../users/user-editor.element";
import { ProxyService } from "../../services/proxy.service";
import { DestinationService } from "../../services/destination.service";

@customElement("se-organization-settings")
export class SeOrganizationSettingsElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _modalService: ModalDialogService;
    private _menuService: MenuService;
    private _settings: AppSettings;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _proxyService: ProxyService;
    private _destinationService: DestinationService;
    private _columns: DataGridColumn[] = [];
    @state() private _currentSlot: string = 'users';
    @state() private _showInvite = false;
    @state() private _menuItems = [];
    @state() private _titleOverride: string = '';
    @state() private _currentBreadcrumb;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);

        this._settings = container.resolve(AppSettings);
        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
        this._proxyService = container.resolve(ProxyService);
        this._destinationService = container.resolve(DestinationService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -5;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._initializeMenuItems();
        
        this.handleRouteChange();
        window.addEventListener('vaadin-router-location-changed', () => this.handleRouteChange());
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('vaadin-router-location-changed', () => this.handleRouteChange());
    }

    private _getBreadcrumbFromPath(): string {
        const pathParts = window.location.pathname.split('/');
        const lastPart = pathParts[pathParts.length - 1];
        // url to readable names
        const routeMap: { [key: string]: string } = {
            'users': 'Users',
            'proxies': 'Proxy Pools',
            'destinations': 'Destinations',
            'transactions': 'Transactions',
            'account-logs': 'Audit Logs',
            'agent-logs': 'Audit Agents',
            'run-logs': 'Audit Runs',
            'login-logs': 'Audit Logins'
        };

        return routeMap[lastPart] || lastPart;
    }

    private async handleRouteChange() {
        const pathParts = window.location.pathname.split('/');

        if (pathParts.includes('org')) {
            if (pathParts.includes('api-keys')) {
                // handle api keys special url case
                const userId = pathParts[pathParts.indexOf('api-keys') + 1];
                if (pathParts.includes('edit')) {
                    const keyId = pathParts[pathParts.indexOf('edit') + 1];
                    await this.updateApiKeyTitle(userId, keyId);
                } else {
                    await this.updateApiKeysListTitle(userId);
                }
            } else if (pathParts.includes('edit')) {
                // handle edit case
                const entityType = pathParts[pathParts.indexOf('edit') - 1];
                const entityId = pathParts[pathParts.indexOf('edit') + 1];
                await this.updateEditTitle(entityType, entityId);
            } else if (pathParts.includes('new') || pathParts.includes('invite')) {
                // handle new case
                const entityType = pathParts[pathParts.indexOf(pathParts.includes('new') ? 'new' : 'invite') - 1];
                await this.updateNewTitle(entityType);
            } else {
                // handle default case
                this._titleOverride = '';
                this._currentBreadcrumb = this._getBreadcrumbFromPath();
            }
        }
    }

    private async updateApiKeyTitle(userId: string, keyId: string) {
        const result = await this._userService.api.getAsync(parseInt(userId));
        if (result.isOk) {
            this._currentBreadcrumb = html`<a href="/org/users">Users</a><span>/</span><a href="/org/users/edit/${userId}">${result.value.name}</a> <span>/</span> <a href="/org/users/api-keys/${userId}">API Keys</a> <span>/</span> <span>Edit</span>`;
            this._titleOverride = result.value.name + "'s API Key";
        }
    }

    private async updateApiKeysListTitle(userId: string) {
        const result = await this._userService.api.getAsync(parseInt(userId));
        if (result.isOk) {
            this._currentBreadcrumb = html`<a href="/org/users">Users</a><span>/</span><a href="/org/users/edit/${userId}">${result.value.name}</a> <span>/</span> <span>API Keys</span>`;
            this._titleOverride = result.value.name + "'s API Keys";
        }
    }

    private async updateEditTitle(entityType: string, entityId: string) {
        let result;
        switch (entityType) {
            case 'users':
                result = await this._userService.api.getAsync(parseInt(entityId));
                if (result.isOk) {
                    this._currentBreadcrumb = html`<a href="/org/users">Users</a><span>/</span><a href="/org/users/edit/${entityId}">${result.value.name}</a> <span>/</span> <span>Edit</span>`;
                    this._titleOverride = "User";
                }
                break;
            case 'proxies':
                result = await this._proxyService.api.getProxyPoolAsync(parseInt(entityId));
                if (result.isOk) {
                    this._currentBreadcrumb = html`<a href="/org/proxies">Proxy Pools</a><span>/</span><a href="/org/proxies/edit/${entityId}">${result.value.name}</a> <span>/</span> <span>Edit</span>`;
                }
                break;
            case 'destinations':
                result = await this._destinationService.api.getDestinationAsync(parseInt(entityId));
                if (result.isOk) {
                    this._currentBreadcrumb = html`<a href="/org/destinations">Destinations</a><span>/</span><a href="/org/destinations/edit/${entityId}">${result.value.destinationName}</a> <span>/</span> <span>Edit</span>`;
                }
                break;
        }
    }

    private async updateNewTitle(entityType: string) {
        switch (entityType) {
            case 'users':
                this._currentBreadcrumb = html`<a href="/org/users">Users</a><span>/</span><span>Invite</span>`;
                break;
            case 'proxies':
                this._currentBreadcrumb = html`<a href="/org/proxies">Proxy Pools</a><span>/</span><span>New</span>`;
                break;
            case 'destinations':
                this._currentBreadcrumb = html`<a href="/org/destinations">Destinations</a><span>/</span><span>New</span>`;
                break;
        }
        this._currentBreadcrumb = this._titleOverride;
    }

    public async onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ){
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }            
    }

    private _handleNavChange(e: CustomEvent) {
        this._currentSlot = e.detail.slotName;
    }

    private _initializeMenuItems() {
        const baseItems = [
            { label: "Users", path: "users" },
            { label: "Proxy Pools", path: "proxies" },
            { label: "Destinations", path: "destinations" },
            { label: "Transactions", path: "transactions" }
        ];

        const adminItems = this._authService.isOrgAdmin ? [
            { label: "Audit Logs", path: "account-logs" },
            { label: "Audit Agents", path: "agent-logs" },
            { label: "Audit Runs", path: "run-logs" },
            { label: "Audit Logins", path: "login-logs" }
        ] : [];

        this._menuItems = [...baseItems, ...adminItems];
    }

    render() {
        return html`
            <div class="body">
                <div class="top-bar">
                    <div class="breadcrumbs">
                        <a>Manage</a>
                        <span>/</span>
                        <a href="/org/${window.location.pathname?.split('/')?.[2] || 'users'}">${this._authService.user.organizationName}</a>
                        ${this._currentBreadcrumb ? html`
                            <span>/</span>
                            ${this._currentBreadcrumb}
                        ` : ''}
                    </div>
                </div>
                <se-nav 
                    baseUrl="/org" 
                    .menuItems=${this._menuItems}
                    .titleOverride=${this._titleOverride}
                >
                    <slot></slot>
                </se-nav>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        .top-bar {
            background-color: var(--color-blue-25);
            padding: 15px;
            border-bottom: solid 1px var(--color-navy-3);
            width: 100%;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a{
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover{
            text-decoration: underline;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .header {
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
            margin-bottom: 10px;
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
        }
    `;
}

