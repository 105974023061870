import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { LabelService } from "../../services/label.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { SpaceService } from "../../services/space.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { ConfigType } from "../../enums/config-type";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { isReadyToRun, isRunningOnServerOrQueingOrWaiting, RunStatus } from "../../enums/run-status";
import { ConfigDashboardViewModel } from "../../models/config-dashboard-view-model";
import { ConfigsSummaryViewModel } from "../../models/configs-summary-view-model";
import { ValidationStatus } from "../../enums/validation-status";
import { ConfigService } from "../../services/config.service";
import { ConfigApi } from "../../services/config.api";
Chart.register(...registerables);
import { Chart, registerables } from "chart.js";
import { SeDropDownButton } from "../components/dropdown-button.element";
import { ServerService } from "../../services/server.service";
import "./welcome.element";

@customElement("se-dashboard")
export class SeDashboardElement extends LitElement {
    private _configService: ConfigService;
    private _serverService: ServerService;
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _labelService: LabelService;
    private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn: string;
    private _sortOrder = -1;

    @state() private _isLoading = true;

    @state() private _data = [];

    @state() private _recentUpdatedData;
    @state() private _recentRunningAgents;
    private _recentRunningColumns: DataGridColumn[] = [];
    private _recentUpdatedColumns: DataGridColumn[] = [];
    @state() private _recentFailedRunsData;
    private _recentFailedRunsColumns: DataGridColumn[] = [];
    @state() private _summaryData;
    private _summaryColumns: DataGridColumn[] = [];

    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    private _configApi: ConfigApi;

    @query("#newAgentButton") private _newAgentElement: SeDropDownButton;

    @query("#doughnutChartCanvas") private _doughnutChartCanvas: HTMLCanvasElement;
    @query("#trendsChartCanvas") private _trendsChartCanvas: HTMLCanvasElement;
    private doughnutChart?: Chart;
    private trendsChart?: Chart;
    private _last2WeeksData;
    private _generalRunsStatus: number[];
    private _agentCount: number;



    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._serverService = container.resolve(ServerService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);
        this._labelService = container.resolve(LabelService);
        this._spaceService = container.resolve(SpaceService);
        this._configService = container.resolve(ConfigService);
        this._configApi = container.resolve(ConfigApi);

    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            { text: "Edit", action: this.editSpace.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteLabelAsync.bind(this, row, col) },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
    }

    private editSpace(row: any, col: DataGridColumn) {
        Router.go(`/edit/space/${row.id}`);
    }
    private allOrgRunsFailed() {
        Router.go(`/org-runs?status=${RunStatus.failed},${RunStatus.failure}`);
    }
    private allOrgRunsNotFailed() {
        Router.go(`/org-runs`);
    }

    private async deleteLabelAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Space",
            body: `Are you sure you want to delete ${row.name} and ALL its content?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._spaceService.api.deleteAsync(row.id);
            if (result.isOk) {
                await this._userState.refreshUserSpacesAsync(this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder);
                this.loadData();
                this._userState.userSpacesChanged.triggerVoid();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    firstUpdated() {
        this.loadDoughnutChart = this.loadDoughnutChart.bind(this);
        window.addEventListener('resize', this.loadDoughnutChart);
    }

    private async loadData() {
        this._columns = [
            {
                field: "name",
                title: "Space Name",
                cellStyle: { textDecoration: "underline" },
                sortable: true,
                action: this.editSpace.bind(this),
            },
            { field: "scope", title: "Template Scope", sortable: true },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
        this._recentUpdatedColumns = [
            {
                field: "configType",
                cellStyle: { width: "0px", "text-align": "center" },
                title: "",
                template: (row) =>
                    html`${choose(
                        row.configType,
                        [
                            [ConfigType.Agent, () => this.getIconTemplate(row.validationStatus, "fa-robot")],
                            [ConfigType.Template, () => this.getIconTemplate(row.validationStatus, "fa-cubes")],
                            [
                                ConfigType.SharedFile,
                                () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-file"></fa-icon>`,
                            ],
                        ],
                        () => html`${row.configType}`
                    )}`,
            },
            {
                field: "name",
                title: "Agent",
                action: (row) => this.openDetails(row),
            },
            {
                field: "spaceName",
                title: "Space",
                action: (row) => this.openSpace(row),
            },
            {
                field: "updated",
                title: "Updated",
                align: "center",
                template: (row) => html`${(new Date(row.updated)).toLocaleDateString()} ${(new Date(row.updated)).toLocaleTimeString()}`,
            },

        ];

        this._recentFailedRunsColumns = [
            {
                field: "configType",
                cellStyle: { width: "0px", "text-align": "center" },
                title: "",
                template: (row) =>
                    html`${choose(
                        row.configType,
                        [
                            [ConfigType.Agent, () => this.getIconTemplate(row.validationStatus, "fa-robot")],
                            [ConfigType.Template, () => this.getIconTemplate(row.validationStatus, "fa-cubes")],
                            [
                                ConfigType.SharedFile,
                                () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-file"></fa-icon>`,
                            ],
                        ],
                        () => html`${row.configType}`
                    )}`,
            },
            {
                field: "name",
                title: "Agent",
                action: (row) => this.openDetails(row),
            },
            {
                field: "spaceName",
                title: "Space",
                action: (row) => this.openSpace(row),
            },
            /*            {
                            field: "startTime",
                            title: "Start",
                            align: "center",
                            template: (row) => {
                                return when(row.startTime, () => html`${(new Date(row.startTime)).toLocaleDateString()} ${(new Date(row.startTime)).toLocaleTimeString()}`);
                            },
                        },*/
            {
                field: "endTime",
                title: "Finish",
                align: "center",
                template: (row) => {
                    return when(row.endTime, () => html`${(new Date(row.endTime)).toLocaleDateString()} ${(new Date(row.endTime)).toLocaleTimeString()}`);
                },
            },
        ];

        this._recentRunningColumns = this._recentFailedRunsColumns.concat(
            {
                field: "status",
                title: "Status",
                align: "center",
                template: (row) => {
                    return row.configType !== ConfigType.SharedFile
                        ? html`<a ${htmlTitle("View run details")} href="javascript:;" style="width: 100%; max-width: 110px; text-decoration: none;" @click=${() => this.openActiveRuns(row)}
                                  >${choose(
                            row.status,
                            [
                                [
                                    undefined || null,
                                    () =>
                                        html`<se-status status-message="No Activity" status-color="--color-gray-4"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.success,
                                    () =>
                                        html`<se-status status-message="Success" status-color="--color-status-blue"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.failure,
                                    () =>
                                        html`<se-status status-message="Failed" status-color="--color-status-red"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.failed,
                                    () =>
                                        html`<se-status status-message="Failed" status-color="--color-status-red"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.completed,
                                    () =>
                                        html`<se-status status-message="Completed" status-color="--color-status-blue"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.running,
                                    () =>
                                        html`<se-status status-message="Running" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.exporting,
                                    () =>
                                        html`<se-status status-message="Exporting" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.starting,
                                    () =>
                                        html`<se-status status-message="Starting" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.stopped,
                                    () =>
                                        html`<se-status status-message="Stopped" status-color="--color-gray-4"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.stopping,
                                    () =>
                                        html`<se-status status-message="Stopping" status-color="--color-purple"
                                                      ></se-status
                                                  >`,
                                ],
                                [
                                    RunStatus.queuing,
                                    () =>
                                        html`<span style="color:var(--color-gray-4);text-decoration: underline;">Queuing</span>`,
                                ],
                                [
                                    RunStatus.waiting,
                                    () => html`<span style="color:teal;text-decoration: underline;">Waiting</span>`,
                                ],
                            ],
                            () => html`${row.status}`
                        )}</a
                              >`
                        : html``;
                }
            },
        );

        this._summaryColumns = [
            {
                name: "", cellStyle: { width: "0" }
            },
            {
                field: "spaceName",
                title: "Space",
                action: (row) => this.openSpace(row),
            },
            {
                title: "Stats",
                headerStyle: { textAlign: "center" },
                cellStyle: { width: "50%" },
                template: (row) => {
                    return html`${this.generateStatsBars(row.stats)}`;
                },
            },
            { name: "", cellStyle: { width: "0" } }
        ];


        try {
            const result = await this._userState.getUserSpacesAsync(this._pageIndex, this._recordsPerPage);
            if (result.isOk) {
                this._data = result.value;
                this._totalRecordCount = this._userState.totalSpaceCount;
                await this.loadSummaryAsync()
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }
    private async loadSummaryAsync() {


        const SpacesSummaries = await this._configApi.getSpacesSummariesAsync();
        if (SpacesSummaries.isOk) {
            this._recentFailedRunsData = SpacesSummaries.value.recentFailedAgents
            this._recentUpdatedData = SpacesSummaries.value.recentUpdatedAgents
            this._recentRunningAgents = SpacesSummaries.value.recentRunningAgents

            // sort spaceSummaries array by the record property of 'spaceName' asc
            this._summaryData = SpacesSummaries.value.spaceSummaries;
            this._summaryData.sort((a, b) => {
                if (a.spaceName < b.spaceName) {
                    return -1;
                }
                if (a.spaceName > b.spaceName) {
                    return 1;
                }
                return 0;
            });

            this._generalRunsStatus = [
                SpacesSummaries.value.stats.successCount,
                SpacesSummaries.value.stats.failCount,
                SpacesSummaries.value.stats.notRunCount,
                SpacesSummaries.value.stats.progressCount,
            ];
            this._agentCount = SpacesSummaries.value.agentsCount;
            this._last2WeeksData = SpacesSummaries.value.last2WeeksByDay;
            console.log(this._generalRunsStatus);
            this.loadDoughnutChart();
            this.loadTrendsChart();


            this.loadDoughnutChart();
            this.loadTrendsChart();

        } else {
            this._toasterService.showUnexpectedError(SpacesSummaries.err.message);
        }



    }
    private getStatus(color: string, text: string, row: any): TemplateResult {
        if (row.message) {
            return html`<span @click=${() => this.openActiveRuns(row)} ${htmlTitle(row.message)} style="border-bottom: 1px dotted;color:${color};cursor:pointer;user-select: none;">${text}</span>`;
        } else {
            return html`<span @click=${() => this.openActiveRuns(row)} style="color:${color};text-decoration: underline;cursor:pointer;">${text}</span>`;
        }
    }
    private generateStatsBars(stats): TemplateResult {
        let total = stats.successCount + stats.failCount + stats.progressCount + stats.notRunCount;

        if (total === 0) {
            return html`
            <div style="display: flex; height: 20px; background-color: #e0e0e0; border-radius: 3px; overflow: hidden;">
                <div style="display: flex; justify-content: center; align-items: center; color: white; font-weight: bold; font-size: 14px; height: 100%; background-color: var(--color-gray-4); width: 100%;">0</div>
            </div>
        `;
        }
        const failWidth = (stats.failCount / total) * 100;
        const progressWidth = (stats.progressCount / total) * 100;
        const successWidth = (stats.successCount / total) * 100;
        const notRunWidth = (stats.notRunCount / total) * 100;

        return html`
        <div style="display: flex; height: 20px; background-color: #e0e0e0; border-radius: 3px; overflow: hidden;">
            <div style="display: ${stats.failCount == 0 ? "none" : "flex"}; justify-content: center; align-items: center; color: white; font-weight: bold; font-size: 14px; height: 100%; background-color: var(--color-status-red); width: ${failWidth}%;">${stats.failCount}</div>
            <div style="display: ${stats.progressCount == 0 ? "none" : "flex"}; justify-content: center; align-items: center; color: white; font-weight: bold; font-size: 14px; height: 100%; background-color: var(--color-purple); width: ${progressWidth}%;">${stats.progressCount}</div>
            <div style="display: ${stats.successCount == 0 ? "none" : "flex"}; justify-content: center; align-items: center; color: white; font-weight: bold; font-size: 14px; height: 100%; background-color: var(--color-status-blue); width: ${successWidth}%;">${stats.successCount}</div>
            <div style="display: ${stats.notRunCount == 0 ? "none" : "flex"}; justify-content: center; align-items: center; color: white; font-weight: bold; font-size: 14px; height: 100%; background-color: var(--color-gray-4); width: ${notRunWidth}%;">${stats.notRunCount}</div>
        </div>
    `;
    }


    private getIconTemplate(validationStatus: ValidationStatus, icon: string) {
        return html`<span
            ${validationStatus !== ValidationStatus.Valid
                ? htmlTitle(validationStatus === ValidationStatus.Invalid ? "Agent is invalid." : "Agent has not been validated.")
                : undefined}
            ><fa-icon
                class="icon"
                single-color=${validationStatus === ValidationStatus.Valid
                ? "gray"
                : validationStatus === ValidationStatus.Invalid
                    ? "salmon"
                    : "goldenrod"}
                fa-class="far ${icon}"
            ></fa-icon
        ></span>`;
    }
    private openDetails(row: ConfigDashboardViewModel) {
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = 0;
        Router.go(`/space/${row.spaceId}/config/${row.id}/details/runs`);
    }
    private openSpace(row: ConfigDashboardViewModel) {
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = 0;
        Router.go(`/space/${row.spaceId}`);
    }
    async saveTitleEditorAsync(newValue: string, row?: ConfigDashboardViewModel): Promise<{ success: boolean; errorMessage?: string }> {
        if (newValue === "invalid") {
            return new Promise((resolve) => {
                resolve({ success: false, errorMessage: "Value cannot change." });
            });
        } else {
            const result = await this._configService.api.renameAsync(row.id, newValue);
            if (result.isOk) {
                if (row.configType === ConfigType.Template) {
                    this.refresh();
                }
                return { success: true };
            } else {
                return { success: false, errorMessage: result.err.message };
            }
        }
    }
    private openActiveRuns(row: ConfigDashboardViewModel) {
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = 0;
        Router.go(`/space/${row.spaceId}/config/${row.id}/details/run-details/run/${row.lastRunId}`);
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private gridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private async sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        await this._userState.refreshUserSpacesAsync(this._pageIndex, this._recordsPerPage, sortColumn, sortOrder);
        this.loadData();
        this._userState.userSpacesChanged.triggerVoid();

        //this._data.sort((a, b) => a[sortColumn] < b[sortColumn] ? -sortOrder : (a[sortColumn] > b[sortColumn] ? sortOrder : 0));
    }

    private async onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;

        await this._userState.refreshUserSpacesAsync(this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder);
        this.loadData();
        this._userState.userSpacesChanged.triggerVoid();
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Spaces",
            body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name} and ALL its content` : `${count} spaces and ALL their content`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const spaceIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._spaceService.api.deleteAllAsync(spaceIds);
            if (result.isOk) {
                await this._userState.refreshUserSpacesAsync(this._pageIndex, this._recordsPerPage, this._sortColumn, this._sortOrder);
                this.loadData();
                this._userState.userSpacesChanged.triggerVoid();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    newSpace() {
        Router.go(`/edit/space`);
    }

    loadDoughnutChart() {

        const blueBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--color-status-blue').trim();
        const redBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--color-status-red').trim();
        const purpleBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--color-purple').trim();
        const grayBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--color-gray-4').trim();


        const totalRuns = this._generalRunsStatus.reduce((acc, val) => acc + val, 0);
        const passCount = this._generalRunsStatus[0];
        let passPercentage = ((passCount / totalRuns) * 100).toFixed(2);
        if (passPercentage === "NaN") {
            passPercentage = "0";
        }
        const ctx = this._doughnutChartCanvas;
        if (this.doughnutChart) {
            this.doughnutChart.destroy();
        }
        let labels = ['Run Status']
        let datasets = [
            {
                label: 'Success',
                data: [this._generalRunsStatus[0]],
                backgroundColor: blueBackgroundColor,
                borderWidth: 0,
            },
            {
                label: 'Failed',
                data: [this._generalRunsStatus[1]],
                backgroundColor: redBackgroundColor,
                borderWidth: 0,
            },
            {
                label: 'In Progress',
                data: [this._generalRunsStatus[3]],
                backgroundColor: purpleBackgroundColor,
                borderWidth: 0,
            },
            {
                label: 'Not Run',
                data: [this._generalRunsStatus[2]],
                backgroundColor: grayBackgroundColor,
                borderWidth: 0,
            }
        ]

        const nonZeroDatasets = datasets.filter(ds => ds.data.some(value => value !== 0));

        const datasetsData = nonZeroDatasets.map((dataset, index) => {
            let borderRadius;

            const isLast = index === nonZeroDatasets.length - 1;
            const isFirst = index === 0;


            if (isFirst) {
                borderRadius = {
                    topLeft: 3,
                    bottomLeft: 3,
                    topRight: 0,
                    bottomRight: 0
                };
            } else if (isLast) {
                borderRadius = {
                    topLeft: 0,
                    bottomLeft: 0,
                    topRight: 3,
                    bottomRight: 3
                };
            } else {
                borderRadius = 0;
            }

            return {
                ...dataset,
                borderRadius: borderRadius,
                barThickness: 25,
                borderSkipped: false
            };
        })

        const allValuesZero = this._generalRunsStatus.every(value => value === 0);
        if (allValuesZero) {
            this._generalRunsStatus = [0, 0, 1, 0]; 
            labels = [''];
            datasets = [
                {
                    label: 'Not Run',
                    data: [1],
                    backgroundColor: grayBackgroundColor,
                    borderWidth: 0,
                }
            ]
        }

        this.doughnutChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasetsData,
            },
            options: {
                indexAxis: 'y',
                scales: {
                    x: {
                        stacked: true,
                        display: false,
                        beginAtZero: true,
                        max: totalRuns, 
                    },
                    y: {
                        stacked: true,
                        display: false,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: !allValuesZero,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });

    }

    private newMenu(): MenuItem[] {
        return [
            {
                text: "New Agent",
                action: () =>
                    this._newAgentElement.doAction(
                        this.openEditor.bind(this, { configType: ConfigType.Agent, spaceId: this._userState.selectedSpaceId })
                    ),
            },
            { text: "-" },
            {
                text: "New Agent Template",
                action: () =>
                    this._newAgentElement.doAction(
                        this.openEditor.bind(this, { configType: ConfigType.Template, spaceId: this._userState.selectedSpaceId })
                    ),
            }
        ];
    }

    private async openEditor(input: { configType?: ConfigType; draftId?: number; configId?: number; spaceId?: number }): Promise<boolean> {
        //get SeEditor URL from server
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        const response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return false;
        } else {
            //url = "https://" + response.value.url;
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        //this.dispatchEvent(new CustomEvent("startloading", { bubbles: true, composed: true, detail: {} }));
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);
        //form.setAttribute("target", "Sequentum Enterprise Editor");

        const params = {
            ...{
                redirectUrl: window.location.href, //callback url when editing is complete
                apiUrl: window.location.protocol + "//" + hostApi + "/api", //internal api url to use server side
                webApiUrl: window.location.origin + "/api", //external api url to use when querying control center
                token: this._authService.token, //auth token
                isInternalOrg: this._authService.user.organizationName === "Sequentum", // Hardcoded, internal organization which has special previllages
                editorServerHost: editorServer, //editor server that editor client should connect to
                orgId: this._authService.user.organizationId,
                isProxyRequired: this._authService.orgSettings.isProxyRequired,
                isDevTools: this._authService.orgSettings.isAllowDevTools,
            },
            ...input,
        };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return true;
    }

    private async newSharedFile(input: { configType?: ConfigType; draftId?: number; configId?: number; spaceId?: number }) {
        Router.go(`/edit/shared-file/new`);
    }



    loadTrendsChart() {
        const ctx = this._trendsChartCanvas;
        const blueColor = getComputedStyle(document.documentElement).getPropertyValue('--color-status-blue').trim();
        const redColor = getComputedStyle(document.documentElement).getPropertyValue('--color-status-red').trim();

        const labels = this._last2WeeksData.map((entry) => {
            const date = new Date(entry.date);
            return `${date.getMonth() + 1}/${date.getDate()}`;
        });

        const failCounts = this._last2WeeksData.map((entry) => entry.failCount);
        const passCounts = this._last2WeeksData.map((entry) => entry.passCount);
        //const stackedPassCounts = this._last2WeeksData.map((entry, index) => entry.passCount + failCounts[index]);

        const redBorderRadius = (index) => {
            return failCounts[index] === 0
                ? { topLeft: 3, topRight: 3, bottomLeft: 3, bottomRight: 3 }
                : { topLeft: 3, topRight: 3, bottomLeft: 0, bottomRight: 0 };
        };
        const blueBorderRadius = (index) => {
            return passCounts[index] === 0
                ? { topLeft: 3, topRight: 3, bottomLeft: 3, bottomRight: 3 } 
                : { topLeft: 0, topRight: 0, bottomLeft: 3, bottomRight: 3 };
        };

        if (this.trendsChart) {
            this.trendsChart.destroy();
        }
        this.trendsChart = new Chart(ctx, {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Success Count",
                        data: passCounts,
                        backgroundColor: blueColor,
                        borderColor: blueColor,
                        borderWidth: 0, 
                        borderRadius: (context) => blueBorderRadius(context.dataIndex),
                        borderSkipped: false,
                    },
                    {
                        label: "Fail Count",
                        data: failCounts,
                        backgroundColor: redColor,
                        borderColor: redColor,
                        borderWidth: 0,
                        borderRadius: (context) => redBorderRadius(context.dataIndex),
                        borderSkipped: false, 
                    }

                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            callback: function (value, index) {
                                return index % 3 === 0 ? labels[index] : "";
                            },
                            color: "black",
                            padding: 10,
                            display: true,
                        },
                        grid: {
                            color: "white",
                            display: true,
                            lineWidth: 1,
                            drawOnChartArea: true,
                            z: 1,
                            drawTicks: true,
                            tickLength: 8,
                            tickWidth: 1,
                            tickColor: "black",
                        },
                        border: {
                            display: true,
                            color: "black",
                            z: 2,
                        },
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        ticks: {
                            callback: function (value) {
                                return Number.isInteger(value) ? value : "";
                            },
                            color: "black",
                            padding: 10,
                            display: false,
                        },
                        title: {
                            display: false,
                        },
                        grid: {
                            color: "white",
                            display: false,
                            drawTicks: true,
                            tickLength: 8,
                            tickWidth: 1,
                            tickColor: "black",
                        },
                        border: {
                            display: false,
                            color: "black",
                            z: 2,
                        },
                        suggestedMax: Math.max(passCounts) + Math.max(failCounts) + 0,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        mode: "index",
                        intersect: false,
                        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
                        callbacks: {
                            label: function (tooltipItem) {
                                if (tooltipItem.datasetIndex === 1) {
                                    return `Fail Count: ${failCounts[tooltipItem.dataIndex]}`;
                                } else {
                                    return `Success Count: ${passCounts[tooltipItem.dataIndex]}`;
                                }
                            },
                        },
                    },
                },
            },
        });


    }
    renderLegend() {
        if (!this._generalRunsStatus) {
            return ''
        }
        const success = this._generalRunsStatus[0]
        const failed = this._generalRunsStatus[1]
        const notRun = this._generalRunsStatus[2]
        const running = this._generalRunsStatus[3]
        const total = success + failed + notRun + running;

        const successPercentage = total > 0 ? Math.round((success / total) * 100) : 0;
        const failedPercentage = total > 0 ? Math.round((failed / total) * 100) : 0;
        const runningPercentage = total > 0 ? Math.round((running / total) * 100) : 0;
        const notRunPercentage = total > 0 ? Math.round((notRun / total) * 100) : 0;


        return html`
      <div class="legend" style="opacity: ${this._isLoading ? '0' : '1'};">
        ${success > 0
                ? html`
              <div class="legend-item">
                <div class="circle success"></div>
                <span class="success">${successPercentage}% </span>&nbsp;Success
              </div>
            `
                : ''}
        ${failed > 0
                ? html`
              <div class="legend-item">
                <div class="circle failed"></div>
                <span class="failed">${failedPercentage}%</span>&nbsp;Failed
              </div>
            `
                : ''}
        ${running > 0
                ? html`
              <div class="legend-item">
                <div class="circle running"></div>
                <span class="running">${runningPercentage}%</span>&nbsp;Running
              </div>
            `
                : ''}
        ${notRun > 0
                ? html`
              <div class="legend-item">
                <div class="circle not-run"></div>
                <span class="not-run">${notRunPercentage}%</span>&nbsp;Not Run
              </div>
            `
                : ''}
      </div>
    `;
    }


    render() {
        return html`
            <div class="body">
                <div class="top-header">
                    <div class="title-header"><h1>Home</h1></div>
                    ${this._authService.user.organizationId == this._authService.user.ownerOrganizationId ? html`
                    <se-dropdown-button
                        id="newAgentButton"
                        text="New Agent"
                        .menu=${this.newMenu()}
                        .action="${() => this.openEditor({ configType: ConfigType.Agent, spaceId: this._userState.selectedSpaceId })}"
                        action-text="Opening..."
                        action-delay="500"
                        min-sizing="growth"
                    ></se-dropdown-button>` : ''
                    }

                </div>
                <div class="columns" style="min-height: 190px;">
                    <div class="container-content card-container" style="flex: 1;">
                    <div id="loading" style="height: 100%; width: 100%; display: ${this._isLoading ? 'flex' : 'none'}; flex-direction: column; align-items: center;">
                        <div style="margin:auto 0 auto 0;">
                            <div style="margin:0 auto 0 auto; width:50px;">
                            </div>
                            <div style="margin-top:8px; width: 400px; text-align: center; color: gray; font: var(--font);font-size:0.9em;">
                                <fa-icon style="font-size:0.9em" fa-class="far fa-spinner fa-spin"> </fa-icon>&nbsp;&nbsp;<span>Loading...</<span>
                            </div>
                        </div>
                    </div>
                        <div class="left-chart" style="justify-content: space-around;">
                            
                            <div class="count" style="flex: 1; opacity: ${this._isLoading ? '0' : '1'}; ">
                                <span style="font-size: 2rem; font-weight: 600;">${this._agentCount}</span>
                                <br>
                                <span style="color: #000;font-weight: 600;">Total Agents</span>
                            </div>
                            <div style="height: calc(20vh - 50px);flex: 1;max-width: 100%; opacity: ${this._isLoading ? '0' : '1'};">
                                <canvas style="min-height: 50px;max-height: 50px;height: 50px;max-width: 100%;" id="doughnutChartCanvas"></canvas>
                            </div>
                            ${this.renderLegend()}
                        </div>
                    </div>
                    <div class="container-content card-container" style="flex: 1;">
                        <div id="loading" style="height: 100%; width: 100%; display: ${this._isLoading ? 'flex' : 'none'}; flex-direction: column; align-items: center;">
                            <div style="margin:auto 0 auto 0;">
                                <div style="margin:0 auto 0 auto; width:50px;">
                                </div>
                                <div style="margin-top:8px; width: 400px; text-align: center; color: gray; font: var(--font);font-size:0.9em;">
                                    <fa-icon style="font-size:0.9em" fa-class="far fa-spinner fa-spin"> </fa-icon>&nbsp;&nbsp;<span>Loading...</<span>
                                </div>
                            </div>
                        </div>
                        <div style="margin-bottom: 0;opacity: ${this._isLoading ? '0' : '1'};" class="h3">Run History</div>
                        <div class="right-chart" style="width: 100%;min-height: 120px;height: calc(20vh - 50px);padding-top: 15px;opacity: ${this._isLoading ? '0' : '1'};">
                            <canvas style="" id="trendsChartCanvas"></canvas>
                        </div>
                        
                    </div>
                </div>
                <div class="columns" style="height: 100%">
                    <div class="card-container" style="display: flex;flex-direction: column;gap: 10px;width: 50%;height: min-content;">
                        <div class="h3">Spaces</div>
                        <se-data-grid style="flex: 1;"
                            class="grid" .isLoading=${this._isLoading}
                            .rows=${this._summaryData}
                            .recordsPerPage=${1000}
                            .pageIndex=${this._pageIndex}
                            .columns=${this._summaryColumns}
                            .defaultSorted=${this._sortColumn}
                        ></se-data-grid>
                    </div>
                    <div style="display: flex;flex-direction: column;gap: 15px;width: 50%;">
                       <span class="card-container" style="display: flex;flex-direction: column;">
                            <span style="display: flex;align-items: center;justify-content: flex-start;gap: 2%;margin-bottom: 5px;">
                                <div class="h3">Recently Failed</div>
                                <a class="more-btn" style="" @click=${this.allOrgRunsFailed}>more</a>
                            </span>
                            <se-data-grid class="grid" .isLoading=${this._isLoading} .rows=${this._recentFailedRunsData} .recordsPerPage=${1000} .pageIndex=${this._pageIndex} .columns=${this._recentFailedRunsColumns} .defaultSorted=${this._sortColumn}>
                            </se-data-grid>
                            ${this._recentFailedRunsData?.length !== 0 ? `` : html`<p class="" style="text-align: center;color: var(--color-gray-4);">No agents have failed recently</p>`}
                       </span>
                       <span class="card-container" style="display: flex;flex-direction: column;">
                            <span style="display: flex;align-items: center;justify-content: flex-start;gap: 2%;margin-bottom: 5px;">
                                <div class="h3">Recently Run</div>
                                <a class="more-btn" style="" @click=${this.allOrgRunsNotFailed}>more</a>
                            </span>
                            <se-data-grid class="grid" .isLoading=${this._isLoading} .rows=${this._recentRunningAgents} .recordsPerPage=${1000} .pageIndex=${this._pageIndex} .columns=${this._recentRunningColumns} .defaultSorted=${this._sortColumn}>
                            </se-data-grid>
                            ${this._recentRunningAgents?.length !== 0 ? `` : html`<p class="" style="text-align: center;color: var(--color-gray-4);">No agents were run recently</p>`}
                       </span>
                       <span class="card-container" style="display: flex;flex-direction: column;">
                            <span style="display: flex;align-items: center;justify-content: flex-start;gap: 2%;margin-bottom: 5px;">
                                <div class="h3">Recently Updated</div>
                            </span>
                            <se-data-grid class="grid" .isLoading=${this._isLoading} .rows=${this._recentUpdatedData} .recordsPerPage=${1000} .pageIndex=${this._pageIndex} .columns=${this._recentUpdatedColumns} .defaultSorted=${this._sortColumn}>
                            </se-data-grid>
                            ${this._recentUpdatedData?.length !== 0 ? ``: html`<p class="" style="text-align: center;color: var(--color-gray-4);">No Agents were updated recently</p>`}
                       </span>
                    </div>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            font-color: var(--color-primary);
            height: 100%;
            padding: 15px;
        }
        .more-btn {
            float: right;
            cursor: pointer;
            color: blue;
        }
        .more-btn:hover {
            text-decoration: underline;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .top-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 10px;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .h3 {
            font-size: 1.5em;
            font-weight: 600;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .card-container {
            min-height: 0;
            overflow: hidden;
            padding: 15px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            box-shadow: 2px 2px 2px lightGray;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
        }
        .columns {
            display: flex;
            gap: 15px;
        }
        .left-chart {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .right-chart {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        

        .legend {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 8px;
        }

        .legend-item {
          display: flex;
          align-items: center;
          flex-grow: 1;
        }

        .legend-item .circle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 20px;
        }

        .circle.success {
          background-color: var(--color-status-blue);
        }

        .circle.failed {
          background-color: var(--color-status-red);
        }

        .circle.not-run {
          background-color: var(--color-gray-4);
        }

        .circle.running {
          background-color: var(--color-purple);
        }

        span.success {
          color: var(--color-status-blue);
        }

        span.failed {
          color: var(--color-status-red);
        }

        span.not-run {
          color: var(--color-gray-4);
        }

        span.running {
          color: var(--color-purple);
        }


        .lds-dual-ring,
        .lds-dual-ring:after {
            box-sizing: border-box;
        }

        .lds-dual-ring {
            display: inline-block;
            width: 14px;
            height: 14px;
        }

        .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 14px;
            height: 14px;
            margin: 2px 0px 0 0;
            border-radius: 100%;
            border: 2.4px solid var(--color-primary);
            border-color: var(--color-primary) transparent var(--color-primary) transparent;
            animation: lds-dual-ring 0.9s linear infinite;
        }

        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    `;
}
