import { css, html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { ApiKey } from "../../../models/api-key";
import { FormInputEditorElement } from "../../editors/form/form-input-editor.element";
import { FormDateTimeEditorElement } from "../../editors/form/form-datetime-editor.element";
import "../cards/edit-form-card.element";
import "../cards/card-buttons.element";

@customElement("se-api-key-editor-form")
export class SeApiKeyEditorFormElement extends LitElement {
    @property({ type: Boolean }) isNew = false;
    @property({ type: Object }) apiKey: ApiKey;
    @property({ type: Function }) onSave: (description: string, expiry: string) => Promise<void>;
    @property({ type: Function }) onCancel: () => void;

    @query("#name") private _nameEditor: FormInputEditorElement;
    @query("#expiry") private _expiryEditor: FormDateTimeEditorElement;

    /**
     * Handles the form submission event when saving an API key
     * @param ev - The triggering event
     * @returns Promise<void>
     */
    private _handleSave = async (ev: Event) => {
        ev.preventDefault();
        if (this.reportValidity()) {
            const description = (this.shadowRoot.querySelector("#name") as any).liveValue;
            const expiry = (this.shadowRoot.querySelector("#expiry") as any).liveValue.toISOString();
            await this.onSave(description, expiry);
        }
    };

    /**
     * Validates all form elements in the shadow DOM
     * @returns boolean - True if all elements are valid, false otherwise
     */
    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as any)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    /**
     * Renders the API key editor form component
     * @returns The rendered template
     */
    render() {
        const d = new Date(this.apiKey?.expiry);
        return html`
            <se-edit-form-card
                title="${this.isNew ? 'Add' : 'Edit'}"
            >
                    <se-form-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelHelp="A unique identifier for the API key"
                        size="50"
                        .value=${this.apiKey?.description ?? ""}
                    ></se-form-input-editor>
                    <se-form-datetime-editor
                        id="expiry"
                        type="datetime-local"
                        name="expiry"
                        label="Expiry"
                        labelHelp="When the key should stop working"
                        required
                        .value=${d}
                    ></se-form-datetime-editor>
            </se-edit-form-card>
            <se-card-buttons>
                <se-primary-button @click="${this._handleSave}" text="${this.isNew ? "Add" : "Save"} Key"></se-primary-button>
                <se-secondary-button @click="${this.onCancel}" text="Cancel"></se-secondary-button>
            </se-card-button>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
} 