import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { BaseEditor } from "../editors/base-editor";
import { InputEditorElement } from "../editors/input-editor.element";
import { ModalDialogService } from "../../services/modal-editor.service";
import { AgentPricingService } from "../../services/agent-pricing.service";
import { AgentPricingViewModel } from "../../models/agent-pricing-view-model";

@customElement("se-agent-pricing-editor")
export class SeAgentPricingEditorElement extends LitElement {
    @property({ type: Number }) configId: number;

    private _toasterService: ToasterService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _pricingService: AgentPricingService;
    @state() private _isNew = true;
    private _pricingModel: AgentPricingViewModel;

    @property({ type: Number }) _runUsagePlaceholder: number;
    @property({ type: Number }) _exportDataBandwidthUsagePlaceholder: number;
    @property({ type: Number }) _exportDataCpmUsagePlaceholder: number;
    @property({ type: Number }) _inputCountPlaceholder: number;

    @query("#actions") private _actionsEditor: InputEditorElement;
    @query("#errors") private _errorsEditor: InputEditorElement;
    @query("#totalPages") private _totalPagesEditor: InputEditorElement;
    @query("#dynamicPages") private _dynamicPagesEditor: InputEditorElement;
    @query("#requests") private _requestsEditor: InputEditorElement;
    @query("#exportedRows") private _exportedRowsEditor: InputEditorElement;
    @query("#inputCount") private _inputCountEditor: InputEditorElement;
    @query("#runUsage") private _runUsageEditor: InputEditorElement;
    @query("#exportedDataBandwidth") private _exportedDataBandwidthEditor: InputEditorElement;
    @query("#exportedDataCpm") private _exportedDataCpmEditor: InputEditorElement;
    @query("#proxyUsage") private _proxyUsageEditor: InputEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._pricingService = container.resolve(AgentPricingService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadSystemLevelPricing();
        this.loadAgentPricing();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async loadAgentPricing() {
        const res = await this._pricingService.api.getAsync(this.configId);
        if (res.isOk) {
            if (res.value != null) {
                this._pricingModel = res.value;
                this._isNew = false;
            }
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    async loadSystemLevelPricing() {
        const res = await this._pricingService.api.getSystemLevelPricingAsync();
        if (res.isOk) {
            if (res.value != null) {
                const jsonData: any = res.value;

                // Access the values
                this._runUsagePlaceholder = jsonData.runUsagePrice;
                this._exportDataBandwidthUsagePlaceholder = jsonData.exportDataBandwidthUsagePrice;
                this._exportDataCpmUsagePlaceholder = jsonData.exportDataCpmUsagePrice;
                this._inputCountPlaceholder = jsonData.inputCountPrice;
            }
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    async saveAsync() {
        const pricing: AgentPricingViewModel = {
            orgId: this._authService.user.organizationId,
            configId: this.configId,
            actions: null, //this.isNullOrEmpty(this._actionsEditor.liveValue) ? null : Number(this._actionsEditor.liveValue),
            errors: null, //this.isNullOrEmpty(this._errorsEditor.liveValue) ? null : Number(this._errorsEditor.liveValue),
            totalPages: null, //this.isNullOrEmpty(this._totalPagesEditor.liveValue) ? null : Number(this._totalPagesEditor.liveValue),
            dynamicPages: null, //this.isNullOrEmpty(this._dynamicPagesEditor.liveValue) ? null : Number(this._dynamicPagesEditor.liveValue),
            requests: null, //this.isNullOrEmpty(this._requestsEditor.liveValue) ? null : Number(this._requestsEditor.liveValue),
            proxyUsage: null, //this.isNullOrEmpty(this._proxyUsageEditor.liveValue) ? null : Number(this._proxyUsageEditor.liveValue),
            inputCount: this.isNullOrEmpty(this._inputCountEditor.liveValue) ? null : Number(this._inputCountEditor.liveValue),
            runUsage: this.isNullOrEmpty(this._runUsageEditor.liveValue) ? null : Number(this._runUsageEditor.liveValue),
            exportedData: this.isNullOrEmpty(this._exportedDataBandwidthEditor.liveValue) ? null : Number(this._exportedDataBandwidthEditor.liveValue),
            exportedRows: this.isNullOrEmpty(this._exportedDataCpmEditor.liveValue) ? null : Number(this._exportedDataCpmEditor.liveValue),
        };

        //save agent pricing
        if (this._isNew) {
            const response = await this._pricingService.api.saveAgentPricingAsync(pricing);
            if (response.isOk) {
                this._toasterService.showSuccess(response.value, 30000);
                this.loadAgentPricing();
            } else {
                this._toasterService.showNetworkError(response.err);
            }
        } else {
            //update agent pricing
            const response = await this._pricingService.api.updateAgentPricingAsync(pricing);
            if (response.isOk) {
                this._toasterService.showSuccess(response.value, 30000);
                this.loadAgentPricing();
            } else {
                this._toasterService.showNetworkError(response.err);
            }
        }
    }

    isNullOrEmpty(str) {
        return str === "" || str === null || str === undefined;
    }

    runUsageChange() {
        if (/^\d+(\.\d+)?$/.test(this._runUsageEditor.liveValue)) {
            // Input is a valid number or decimal.
            this._runUsageEditor.setCustomValidity("");
            this._runUsageEditor.reportValidity();
        } else {
            // Input is not a valid number or decimal, reject the input
            this._runUsageEditor.setCustomValidity("Please enter valid numbers.");
        }
    }

    exportedDataBandwidthChange() {
        if (/^\d+(\.\d+)?$/.test(this._exportedDataBandwidthEditor.liveValue)) {
            // Input is a valid number or decimal.
            this._exportedDataBandwidthEditor.setCustomValidity("");
            this._exportedDataBandwidthEditor.reportValidity();
        } else {
            // Input is not a valid number or decimal, reject the input
            this._exportedDataBandwidthEditor.setCustomValidity("Please enter valid numbers.");
        }
    }

    exportedDataCpmChange() {
        if (/^\d+(\.\d+)?$/.test(this._exportedDataCpmEditor.liveValue)) {
            // Input is a valid number or decimal.
            this._exportedDataCpmEditor.setCustomValidity("");
            this._exportedDataCpmEditor.reportValidity();
        } else {
            // Input is not a valid number or decimal, reject the input
            this._exportedDataCpmEditor.setCustomValidity("Please enter valid numbers.");
        }
    }

    inputCountChange() {
        if (/^\d+(\.\d+)?$/.test(this._inputCountEditor.liveValue)) {
            // Input is a valid number or decimal.
            this._inputCountEditor.setCustomValidity("");
            this._inputCountEditor.reportValidity();
        } else {
            // Input is not a valid number or decimal, reject the input
            this._inputCountEditor.setCustomValidity("Please enter valid numbers.");
        }
    }

    proxyUsageChange() {
        if (/^\d+(\.\d+)?$/.test(this._proxyUsageEditor.liveValue)) {
            // Input is a valid number or decimal.
            this._proxyUsageEditor.setCustomValidity("");
            this._proxyUsageEditor.reportValidity();
        } else {
            // Input is not a valid number or decimal, reject the input
            this._proxyUsageEditor.setCustomValidity("Please enter valid numbers.");
        }
    }

    cancel() {
        history.back();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "New" : "Edit"} Agent Pricing</div>
                <div class="scroll-container">
                    <se-input-editor
                        id="runUsage"
                        name="runUsage"
                        type="text"
                        label="Run Usage"
                        labelPosition="top"
                        placeholder=${this._runUsagePlaceholder}
                        input-type="text"
                        size="30"
                        @editorChanged=${this.runUsageChange}
                        .value=${this._pricingModel?.runUsage ?? ""}
                    ></se-input-editor>
                    <se-input-editor
                        id="exportedDataCpm"
                        name="exportedDataCpm"
                        type="text"
                        label="Export CPM"
                        labelPosition="top"
                        placeholder=${this._exportDataCpmUsagePlaceholder}
                        input-type="text"
                        size="30"
                        @editorChanged=${this.exportedDataCpmChange}
                        .value=${this._pricingModel?.exportedRows ?? ""}
                    ></se-input-editor>
                    <se-input-editor
                        id="exportedDataBandwidth"
                        name="exportedDataBandwidth"
                        type="text"
                        label="Export GB"
                        labelPosition="top"
                        placeholder=${this._exportDataBandwidthUsagePlaceholder}
                        input-type="text"
                        size="30"
                        @editorChanged=${this.exportedDataBandwidthChange}
                        .value=${this._pricingModel?.exportedData ?? ""}
                    ></se-input-editor>
                    <se-input-editor
                        id="inputCount"
                        name="inputCount"
                        type="text"
                        label="Input Count"
                        labelPosition="top"
                        placeholder=${this._inputCountPlaceholder}
                        input-type="text"
                        size="30"
                        @editorChanged=${this.inputCountChange}
                        .value=${this._pricingModel?.inputCount ?? ""}
                    ></se-input-editor>
                    <!-- <se-input-editor id="proxyUsage" name="proxyUsage" type="text" label="Proxy Usage" labelPosition="top" input-type="text" size="30" @editorChanged=${this
                        .proxyUsageChange} .value=${this._pricingModel?.proxyUsage ?? ""}></se-input-editor>
                    <se-input-editor id="exportedRows" name="exportedRows" type="text" label="Export CPM" labelPosition="top" input-type="text" size="30" .value=${this
                        ._pricingModel?.exportedRows ?? ""}></se-input-editor>
                    <se-input-editor id="actions" name="actions" type="text" label="Actions" labelPosition="top" input-type="text" size="30" .value=${this
                        ._pricingModel?.actions ?? ""}></se-input-editor>
                    <se-input-editor id="errors" name="errors" type="text" label="Errors" labelPosition="top" input-type="text" size="30" .value=${this
                        ._pricingModel?.errors ?? ""}></se-input-editor>
                    <se-input-editor id="requests" name="requests" type="text" label="Requests" labelPosition="top" input-type="text" size="30" .value=${this
                        ._pricingModel?.requests ?? ""}></se-input-editor>
                    <se-input-editor id="dynamicPages" name="dynamicPages" type="text" label="Dynamic Pages" labelPosition="top" input-type="text" size="30" .value=${this
                        ._pricingModel?.dynamicPages ?? ""}></se-input-editor>
                    <se-input-editor id="totalPages" name="totalPages" type="text" label="Total Pages" labelPosition="top" input-type="text" size="30" .value=${this
                        ._pricingModel?.totalPages ?? ""}></se-input-editor>
                    -->
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Save" : "Update"}"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            min-width: 300px;
            overflow: auto;
            padding: 25px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 20px;
        }
    `;
}
