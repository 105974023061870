import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { AppSettings } from "../../models/app-settings";
import "../billing/transactions.element";
import "../components/side-nav.element";
import { ServerService } from "../../services/server.service";
import { ServerKeysService } from "../../services/server-keys.service";
import { RateLimitService } from "../../services/rate-limit.service";
@customElement("se-admin-settings")
export class SeAdminSettingsElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _settings: AppSettings;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _menuItems = [];
    @state() private _currentBreadcrumb: string = '';
    @state() private _titleOverride;
    private _serverService: ServerService;
    private _serverKeysService: ServerKeysService;
    private _rateLimitService: RateLimitService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);
        this._settings = container.resolve(AppSettings);
        this._serverService = container.resolve(ServerService);
        this._serverKeysService = container.resolve(ServerKeysService);
        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
        this._rateLimitService = container.resolve(RateLimitService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -7;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._initializeMenuItems();

        this._handleRouteChange();
        window.addEventListener('vaadin-router-location-changed', () => this._handleRouteChange());
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('vaadin-router-location-changed', () => this._handleRouteChange());
    }

    private _initializeMenuItems() {
        this._menuItems = [
            { label: "Servers", path: "servers" },
            { label: "Organizations", path: "organizations" },
            { label: "All Runs", path: "all-runs" },
            { label: "System Audit", path: "system-logs" },
            { label: "Rate Limits", path: "rate-limits" },
            { label: "App Settings", path: "app-settings" },
            { label: "Server Keys", path: "server-keys" }
        ];
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    private _getBreadcrumbFromPath(): string {
        const pathParts = window.location.pathname.split('/');
        const lastPart = pathParts[pathParts.length - 1];

        const routeMap: { [key: string]: string } = {
            'servers': 'Servers',
            'organizations': 'Organizations',
            'all-runs': 'All Runs',
            'system-logs': 'System Audit',
            'rate-limits': 'Rate Limits',
            'app-settings': 'App Settings',
            'server-keys': 'Server Keys'
        };

        return routeMap[lastPart] || lastPart;
    }

    private async _handleRouteChange() {
        const pathParts = window.location.pathname.split('/');

        if (pathParts.includes('edit')) {
            const entityType = pathParts[pathParts.indexOf('edit') + 1];
            const entityId = pathParts[pathParts.indexOf('edit') + 2];
            await this._updateEditTitle(entityType, entityId);
        } else {
            this._titleOverride = '';
            this._currentBreadcrumb = this._getBreadcrumbFromPath();
        }

        this._currentBreadcrumb = this._titleOverride || this._currentBreadcrumb;
    }

    private async _updateEditTitle(entityType: string, entityId: string) {
        let result;
        if (!entityId || isNaN(parseInt(entityId))) {
            switch (entityType) {
                case 'server':
                    this._titleOverride = html`<a href="/manage/admin/servers">Servers</a><span>/</span><span>New Run Server</span>`;
                    break;
                case 'server-key':
                    this._titleOverride = html`<a href="/manage/admin/server-keys">Server Keys</a><span>/</span><span>New Server Key</span>`;
                    break;
                case 'rate-limit':
                    this._titleOverride = html`<a href="/manage/admin/rate-limits">Rate Limits</a><span>/</span><span>New Rate-Limit</span>`;
                    break;
                case 'editor-server':
                    this._titleOverride = html`<a href="/manage/admin/servers">Servers</a><span>/</span><span>New Editor Server</span>`;
                    break;
            }
        } else {
            switch (entityType) {
                case 'server':
                    result = await this._serverService.api.getAsync(parseInt(entityId));
                    if (result.isOk) {
                        this._titleOverride = html`<a href="/manage/admin/servers">Servers</a><span>/</span><a href="/manage/admin/edit/server/${entityId}">${result.value.name}</a><span>/</span><span>Edit</span>`;
                    }
                    break;
                case 'server-key':
                    result = await this._serverKeysService.api.getAsync(parseInt(entityId));
                    if (result.isOk) {
                        this._titleOverride = html`<a href="/manage/admin/server-keys">Server Keys</a><span>/</span><a href="/manage/admin/edit/server-key/${entityId}">${result.value.clientName}</a><span>/</span><span>Edit</span>`;
                    }
                    break;
                case 'rate-limit':
                    result = await this._rateLimitService.api.getAsync(parseInt(entityId));
                    if (result.isOk) {
                        this._titleOverride = html`<a href="/manage/admin/rate-limits">Rate Limits</a><span>/</span><a href="/manage/admin/edit/rate-limit/${entityId}">${result.value.domainName}</a><span>/</span><span>Edit</span>`;
                    }
                    break;

            }

        }





    }

    render() {
        return html`
            <div class="body">
                <div class="top-bar">
                    <div class="breadcrumbs">
                        <a>Manage</a>
                        <span>/</span>
                        <a>Admin</a>
                        ${this._currentBreadcrumb ? html`
                            <span>/</span>
                            ${this._currentBreadcrumb}
                        ` : ''}
                    </div>
                </div>
                <se-nav 
                    baseUrl="/manage/admin" 
                    .menuItems=${this._menuItems}
                >
                    <slot></slot>
                </se-nav>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .top-bar {
            background-color: var(--color-blue-25);
            padding: 15px;
            border-bottom: solid 1px var(--color-navy-3);
            width: 100%;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a{
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover{
            text-decoration: underline;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
        }
    `;
}
