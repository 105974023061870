import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { RateLimitService } from "../../services/rate-limit.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "./rate-limit-editor.element";

@customElement("se-rate-limits")
export class SeRateLimitsElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _rateLimitService: RateLimitService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];

    @state() private _data: any[] = [];

    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._rateLimitService = container.resolve(RateLimitService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            { text: "Edit", action: this.editRateLimit.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteRateLimitAsync.bind(this, row, col) },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    private editRateLimit(row: any, col: DataGridColumn) {
        Router.go(`/manage/admin/edit/rate-limit/${row.id}`);
    }

    private async deleteRateLimitAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Organization",
            body: `Are you sure you want to delete ${row.domainName}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            if (row.id === this._authService.user.organizationId) {
                this._toasterService.showError("You cannot delete your own organization.");
            } else {
                const result = await this._rateLimitService.api.deleteAsync(row.id);
                if (result.isOk) {
                    const index = this._data.indexOf(row);
                    this._data.splice(index, 1);
                    if (row.selected) this.gridSelectionChanged();
                    this._dataGrid.requestUpdate();
                } else if (result.isErr) {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        }
    }

    private async loadData() {
        this._columns = [
            {
                field: "domainName",
                title: "Domain Name",
                actionLink: this.editRateLimit,
                getValue: (row) => (row.domainName ? row.domainName : "All domains"),
            },
            {
                field: "organizationName",
                title: "Organization",
                getValue: (row) => (row.organizationName ? row.organizationName : "All organizations"),
            },
            { field: "startDelayConnections", title: "Start Delay Connections", align: "right" },
            {
                field: "connectionDelayIncreaseMs",
                title: "Connection Delay Increase",
                align: "right",
                getValue: (row) => `${row.connectionDelayIncreaseMs ?? ""}${row.connectionDelayIncreaseMs ? "ms" : ""}`,
            },
            {
                field: "requestDelayIncreaseMs",
                title: "Request Delay Increase",
                align: "right",
                getValue: (row) => `${row.requestDelayIncreaseMs ?? ""}${row.requestDelayIncreaseMs ? "ms" : ""}`,
            },
            { field: "minRequestDelayMs", title: "Min. Request Delay ", align: "right" },
            {
                field: "maxConnections",
                title: "Max Connections",
                align: "right",
                template: (row) =>
                    row.maxConnections === 0 ? html`<span style="color:red">Blocked</span>` : html`${row.maxConnections ?? ""}`,
            },
            {
                field: "updated",
                title: "Updated",
                align: "center",
                getValue: (row) => `${new Date(row.updated).toLocaleDateString()} ${new Date(row.updated).toLocaleTimeString()}`,
            },
            {
                field: "created",
                title: "Created",
                align: "center",
                getValue: (row) => `${new Date(row.created).toLocaleDateString()} ${new Date(row.created).toLocaleTimeString()}`,
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
        const result = await this._rateLimitService.api.getAllAsync();
        if (result.isOk) {
            this._data = result.value;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Rate Limits",
            body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].domainName}` : `${count} rate limits`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const rateLimitIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._rateLimitService.api.deleteManyAsync(rateLimitIds);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    newRateLimit() {
        Router.go(`/manage/admin/edit/rate-limit`);
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                    <se-primary-button text="New Rate Limit" @click="${this.newRateLimit}"></se-primary-button>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    placeholder="No rate limits available."
                ></se-data-grid>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
