import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccountAuditLogModel, AuditAccountObjectType, AuditLogOperation } from "../../models/audit-log";
import { AuditLogService } from "../../services/audit-log.service";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";

@customElement("se-account-audit-log")
export class SeAuditAccountLogElement extends LitElement {
    @property() configId;

    private _modalService: ModalDialogService;
    private _auditLogService: AuditLogService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _data: AccountAuditLogModel[];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;

    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    private _authService: AuthService;

    constructor() {
        super();
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._auditLogService = container.resolve(AuditLogService);
        this._authService = container.resolve(AuthService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    // titles strings "configName" to "Config Name"
    private formatKey(key: string): string {
        return key.replace(/([A-Z])/g, ' $1').trim()
            .replace(/^[a-z]/, letter => letter.toUpperCase());
    }

    private async openDetailsAsync(row: AccountAuditLogModel) {
        const details = await this._auditLogService.api.getAccountLogDetailsAsync(row.organizationId, row.id);
        if (details.isOk)
            this._modalService.openObjectInfoDialogAsync({
                title: "Raw Log Entry",
                anyObject: {
                    ...Object.keys(details.value).reduce((data, key) => {
                        data[this.formatKey(key)] = details.value[key];
                        return data;
                    }, {}),
                    ...Object.keys(row).reduce((data, key) => {
                        data[this.formatKey(key)] = row[key];
                        return data;
                    }, {}),
                },
            });
        else this._toasterService.showNetworkError(details.err);
    }

    private async loadData(isShowLoading = true) {
        this._columns = [
            {
                field: "operation",
                title: "",
                getValue: (row) =>
                    row.operation === AuditLogOperation.Add ? "Added" : row.operation === AuditLogOperation.Update ? "Updated" : "Deleted",
                actionLink: (row) => this.openDetailsAsync(row),
            },
            { field: "objectType", title: "Type", getValue: (row) => AuditAccountObjectType[row.objectType] },
            { field: "description", title: "Description" },
            { field: "initiatedBy", title: "Initiated By" },
            {
                field: "created",
                title: "Created",
                align: "center",
                sortable: true,
                template: (row) => {
                    const date = new Date(row.created);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
        ];

        if (isShowLoading) this._isLoading = true;
        try {
            const beforeUtc = new Date();
            beforeUtc.setDate(beforeUtc.getDate() - 30);

            //this._data = [];
            //this._totalRecordCount = 0;

            const result = await this._auditLogService.api.getAccountLogAsync(
                this._authService.user.organizationId,
                beforeUtc.toUTCString(),
                new Date().toUTCString(),
                this._pageIndex,
                this._recordsPerPage
            );
            if (result.isOk) {
                this._data = result.value.auditLog;
                this._totalRecordCount = result.value.totalRecordCount;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private refresh() {
        this.loadData();
    }

    render() {
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-secondary-button
                            @click=${this.refresh}
                            ${htmlTitle("Refresh")}
                            .customStyle=${{ padding: "1px 6px", margin: 0 }}
                            icon="far fa-redo"
                        ></se-secondary-button>
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    placeholder="No data available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
                <se-pagination .recordCount=${this._totalRecordCount} .recordsPerPage=${this._recordsPerPage} @pagechanged=${this.onPageChanged}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
