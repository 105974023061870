import { css, html, LitElement } from "lit";
import { customElement, property } from "lit/decorators.js";

/**
 * A versatile card component that supports different styles and content layouts.
 * 
 * @element se-card
 * 
 * @property {boolean} chart - When true, adjusts layout for chart content with space-around justification
 * @property {string} title - Optional header text to display at the top of the card
 * @property {boolean} showMoreLink - When true, displays a "more" link in the header that emits a 'more' event when clicked
 * @property {string} moreText - Custom text for the "more" link (default: "more")
 * @property {string} emptyText - Optional text to display when the card has no content
 * @property {boolean} simple - When true, applies a wider padding (30px) suitable for simpler content layouts
 * 
 * @fires {CustomEvent} more - Dispatched when the "more" link is clicked
 * 
 * @slot - Default slot for card content
 * 
 * @example
 * // Basic usage
 * <se-card title="My Card">
 *   <div>Card content here</div>
 * </se-card>
 * 
 * @example
 * // Chart card with more link
 * <se-card 
 *   chart 
 *   title="Chart Data" 
 *   showMoreLink 
 *   moreText="View All"
 *   @more=${handleMoreClick}>
 *   <canvas id="myChart"></canvas>
 * </se-card>
 * 
 * @example
 * // Simple card with empty state
 * <se-card
 *   simple
 *   title="Simple View"
 *   emptyText="No data available">
 * </se-card>
 */
@customElement("se-card")
export class SeCardElement extends LitElement {
    @property({ type: Boolean }) chart = false;
    @property({ type: String }) title = "";
    @property({ type: Boolean }) showMoreLink = false;
    @property({ type: String }) moreText = "more";
    @property({ type: String }) emptyText = "";
    @property({ type: Boolean }) simple = false;
    
    render() {
        return html`
            <div class="card-container ${this.chart ? 'chart-container' : ''} ${this.simple ? 'simple' : ''}">
                ${this.title ? html`
                    <div class="header">
                        <div class="h3">${this.title}</div>
                        ${this.showMoreLink ? html`
                            <a class="more-btn" @click=${() => this.dispatchEvent(new CustomEvent('more'))}>
                                ${this.moreText}
                            </a>
                        ` : ''}
                    </div>
                ` : ''}

                <div class="content">
                    ${this.emptyText ? html`
                        <p class="empty-text">${this.emptyText}</p>
                    ` : ''}
                    <slot></slot>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            font: var(--font);
        }

        .card-container {
            min-height: 0;
            overflow: hidden;
            padding: 15px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
            height: 100%;
            display: flex;
            flex-direction: column;
            max-width: 1000px;
        }

        .simple {
            padding: 30px;
        }

        .chart-container {
            justify-content: space-around;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 5px;
            gap: 2%;
        }

        .h3 {
            font-size: 1.5em;
            font-weight: 600;
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .more-btn {
            cursor: pointer;
            color: blue;
        }

        .more-btn:hover {
            text-decoration: underline;
        }

        .empty-text {
            text-align: center;
            color: var(--color-gray-4);
        }
    `;
} 