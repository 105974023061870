import { Router } from "@vaadin/router";
import { singleton } from "tsyringe";

@singleton()
export class RouterService {
    private _router: Router;

    initialize(outlet: HTMLDivElement) {
        this._router = new Router(outlet);
        this._router.setRoutes([
            {
                path: "",
                component: "se-home",
                children: [
                    {
                        path: "/",
                        redirect: "/dashboard",
                    },
                    {
                        path: "/dashboard",
                        component: "se-dashboard",
                    },
                    {
                        path: "/welcome",
                        component: "se-welcome"
                    },
                    {
                        path: "/space/:spaceId?",
                        component: "se-space",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId",
                        component: "se-space",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/template/:templateId",
                        component: "se-space",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/shared-file/:sharedFileId",
                        component: "se-space",
                    },
                    {
                        path: "/edit/space/:spaceId?",
                        component: "se-space-editor",
                    },
                    {
                        path: "/edit/label/:labelId?/space/:spaceId?",
                        component: "se-label-editor",
                    },
                    {
                        path: "/manage/labels/space/:spaceId?",
                        component: "se-labels",
                    },
                    {
                        path: "/manage/spaces",
                        component: "se-spaces",
                    },
                    {
                        path: "/manage/org-servers",
                        component: "se-organization-servers",
                    },
                    {
                        path: "/manage/server-groups",
                        component: "se-server-groups",
                    },
                    {
                        path: "/edit/server-group/:serverGroupId?",
                        component: "se-server-group-editor",
                    },
                    {
                        path: "/manage/user-groups",
                        component: "se-user-groups",
                    },
                    {
                        path: "/edit/user-group/:userGroupId?",
                        component: "se-user-group-editor",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/config/:configId/run/:taskId?",
                        component: "se-start-run",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/task/:taskId?",
                        component: "se-start-run",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/many-runs",
                        component: "se-start-many-runs",
                    },
                    {
                        path: "/space/:spaceId/label/:labelId/template/:templateId/many-runs",
                        component: "se-start-many-runs",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/runs",
                        component: "se-runs",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/tasks",
                        component: "se-agent-tasks",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/agent-pricing",
                        component: "se-agent-pricing-editor",
                    },
                    {
                        path: "/space/:spaceId/shared-file/:configId/details",
                        component: "se-shared-file-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/details/:gridType",
                        component: "se-agent-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/details/:gridType/run/:runId",
                        component: "se-agent-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/run/:runId",
                        component: "se-run-details",
                    },
                    {
                        path: "/space/:spaceId/config/:configId/run-history",
                        component: "se-run-history",
                    },
                    {
                        path: "/edit/shared-file/new",
                        component: "se-shared-file-create",
                    },
                    {
                        path: "/edit/shared-file/:configId?/editor",
                        component: "se-shared-file-editor",
                    },
                    {
                        path: "/admin/organizations/:approvalString?",
                        component: "se-organizations",
                    },
                    {
                        path: "/destinations/oauth-response",
                        component: "se-oauth-response"
                    },
                    {
                        path: "/org",
                        component: "se-organization-settings",
                        children: [
                            {
                                path: "/",
                                redirect: "/org/users"
                            },
                            {
                                path: "/users",
                                component: "se-organization-users"
                            },
                            {
                                path: "/users/invite",
                                component: "se-invite-user"
                            },
                            {
                                path: "/users/edit/:userId",
                                component: "se-user-editor"
                            },
                            {
                                path: "/users/api-keys/:userId",
                                component: "se-api-keys"
                            },
                            {
                                path: "/users/api-keys/:userId/new",
                                component: "se-api-key-editor"
                            },
                            {
                                path: "/users/api-keys/:userId/edit/:apiKeyId",
                                component: "se-api-key-editor"
                            },
                            {
                                path: "/proxies",
                                component: "se-proxies"
                            },
                            {
                                path: "/proxies/new",
                                component: "se-proxy-editor"
                            },
                            {
                                path: "/proxies/edit/:proxyPoolId",
                                component: "se-proxy-editor"
                            },
                            {
                                path: "/destinations",
                                component: "se-destinations"
                            },
                            {
                                path: "/destinations/new",
                                component: "se-destination-editor"
                            },
                            {
                                path: "/destinations/edit/:destinationId",
                                component: "se-destination-editor"
                            },
                            {
                                path: "/transactions",
                                component: "se-transactions"
                            },
                            {
                                path: "/account-logs",
                                component: "se-account-audit-log"
                            },
                            {
                                path: "/agent-logs",
                                component: "se-config-audit-log"
                            },
                            {
                                path: "/run-logs",
                                component: "se-run-audit-log"
                            },
                            {
                                path: "/login-logs",
                                component: "se-login-audit-log"
                            }
                        ]
                    },
                    {
                        path: "/manage/admin",
                        component: "se-admin-settings",
                        children: [
                            {
                                path: "/",
                                redirect: "/manage/admin/servers"
                            },
                            {
                                path: "/servers",
                                component: "se-servers"
                            },
                            {
                                path: "/organizations",
                                component: "se-organizations"
                            },
                            {
                                path: "/all-runs",
                                component: "se-all-runs"
                            },
                            {
                                path: "/system-logs",
                                component: "se-system-audit-log"
                            },
                            {
                                path: "/rate-limits",
                                component: "se-rate-limits"
                            },
                            {
                                path: "/app-settings",
                                component: "se-app-settings"
                            },
                            {
                                path: "/server-keys",
                                component: "se-server-keys"
                            },
                            {
                                path: "/servers",
                                component: "se-servers",
                            },
                            {
                                path: "/edit/editor-server/:serverId?",
                                component: "se-editor-server-editor",
                            },
                            {
                                path: "/edit/server/:serverId?",
                                component: "se-server-editor",
                            },
                            {
                                path: "/app-settings",
                                component: "se-app-settings",
                            },
                            {
                                path: "/all-runs",
                                component: "se-all-runs",
                            },
                            {
                                path: "/subscriptions",
                                component: "se-subscriptions",
                            },
                            {
                                path: "/edit/rate-limit/:rateLimitId?",
                                component: "se-rate-limit-editor",
                            },
                            {
                                path: "/server-keys",
                                component: "se-server-keys",
                            },
                            {
                                path: "/edit/server-key/:serverKeyId?",
                                component: "se-server-key-editor",
                            },
                        ]
                    },
                    {
                        path: "/user",
                        component: "se-user-settings",
                        children: [
                            {
                                path: "/",
                                redirect: "/user/api-keys"
                            },
                            {
                                path: "/api-keys",
                                component: "se-user-api-keys",
                            },
                            {
                                path: "/api-keys/new",
                                component: "se-user-api-key-editor"
                            },
                            {
                                path: "/api-keys/:apiKeyId/edit",
                                component: "se-user-api-key-editor"
                            },
                            {
                                path: "/change-password",
                                component: "se-change-password",
                            },
                            {
                                path: "/logout",
                                component: "se-user-logout",
                            }
                        ]
                    },
                    {
                        path: "/manage/admin/:tab?",
                        component: "se-admin-settings",
                    },
                    {
                        path: "/audit-log/:gridType?",
                        component: "se-audit-log",
                    },
                    {
                        path: "/org-runs",
                        component: "se-org-runs",
                    },
                    {
                        path: "/billing/:tab?",
                        component: "se-billing",
                    },
                    {
                        path: "/usage",
                        component: "se-usage",
                    },
                    {
                        path: "/usage/:checkoutStatus?",
                        component: "se-usage",
                    },
                    {
                        path: "/agent-usage/:pipeId?",
                        component: "se-pipe-usage",
                    },
                    {
                        path: "/invite/user",
                        component: "se-invite-user",
                    },
                    
                ],
            },
            {
                path: '/pending-verification',
                component: 'se-pending-verification'
            },
            {
                path: "/verify-user",
                component: "se-complete-verification",
            },
            {
                path: "/login",
                component: "se-login",
            },
            {
                path: "/forgot-password",
                component: "se-forgot-password",
            },
            {
                path: "/reset-password",
                component: "se-reset-password",
            },
            {
                path: "/create-first-user",
                component: "se-create-first-user",
            },
            {
                path: "/register",
                component: "se-user-registration",
            },
            {
                path: "/verify-email/:token",
                component: "se-email-verification",
            },
            {
                path: "/multi-factor-authentication-setup",
                component: "se-multi-factor-authentication-setup",
            },
            {
                path: "/multi-factor-authentication",
                component: "se-multi-factor-authentication",
            },
        ]);
    }
}
