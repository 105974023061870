import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ConfigFileType } from "se-shared/enums/config-file-type";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { FileUploadApi } from "../../services/file-upload.api";
import { FileUtil } from "../../services/file-util";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ServerService } from "../../services/server.service";
import { UserState } from "../../services/user.state";
import "../components/fa-icon.element";
import "./shared-file-info.element";

type GridType = "info" | "edit";

@customElement("se-shared-file-details")
export class SeAgentDetailsElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _serverService: ServerService;
    private _configService: ConfigService;
    private _fileUploadApi: FileUploadApi;
    private _menuService: MenuService;
    private _modalService: ModalDialogService;

    private _configId: number;
    @state() private _gridType: GridType = "info";
    @state() private _configName: string;
    @state() private _fileType: ConfigFileType;
    @state() private _fileSize: number;
    @state() private _canEditFile = false;
    @state() private _spaceName: string;
    private _spaceId: number;
    private _lastSelectedLabelId: number;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._serverService = container.resolve(ServerService);
        this._configService = container.resolve(ConfigService);
        this._fileUploadApi = container.resolve(FileUploadApi);
        this._menuService = container.resolve(MenuService);
        this._modalService = container.resolve(ModalDialogService);
    }

    async connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();

        if (this._userState.selectedConfigId !== this._configId) {
            const result = await this._configService.api.getNameAndTypeAsync(this._configId);
            if (result.isOk) {
                this._userState.selectedConfigId = this._configId;
                this._userState.selectedConfigName = result.value.name;
                this._userState.selectedConfigType = result.value.type;
                this._userState.selectedConfigValidationStatus = result.value.validationStatus;
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }

        const spaceResult = await this._userState.getSelectedSpace();
        if (spaceResult.isOk) {
            this._spaceName = spaceResult.value.name;
            this._spaceId = spaceResult.value.id;
            this._lastSelectedLabelId = this._userState.getStoredLabelId();
        } else {
            this._toasterService.showNetworkError(spaceResult.err);
        }

        const result = await this._configService.api.getSharedFileDetailsAsync(this._configId);
        if (result.isOk) {
            this._configName = result.value.name;
            this._fileType = result.value.fileType;
            this._fileSize = Math.round((result.value.fileSize ?? 0) / 1024);
            if (this._fileSize === 0) this._fileSize = 1;
            //console.log("this._fileType=" + this._fileType + ";_fileSize=" + this._fileSize);
            //console.log(result);
            this._canEditFile = this._fileType === ConfigFileType.Text && this._fileSize < 1024; //Only allow edit on files less than 1MB in size.
        } else {
            this._configName = result.err.message;
        }
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }
        if (location.params.configId) {
            this._configId = parseInt(location.params.configId.valueOf() as string);
        }
    }

    private goInfo() {
        this._gridType = "info";
    }

    private async downloadFile(configId: number) {
        try {
            const result = await this._fileUploadApi.downloadSharedFileAsync(configId, this._configName);
            if (result.isOk) {
                const data = result.value;
                FileUtil.SaveFileAsText(data, this._configName);
            } else {
                const error = result.err;
                //console.log("Error:" + error);
                this._toasterService.showNetworkError(error);
            }
        } catch (error) {
            //console.log("Error:" + error);
            this._toasterService.showNetworkError(error);
        }
    }

    private editFile(configId: number, configName: string) {
        Router.go(`/edit/shared-file/${configId}/editor?name=${configName}`);
    }

    private getFileSize(fileSize: number) {
        if (fileSize === 0) return html``;
        else return html`&nbsp;(Compressed: ${this._fileSize}KB)`;
    }

    private _goBack() {
        const lastSelectedLabelId = this._userState.getStoredLabelId();
        Router.go(`/space/${this._userState.selectedSpaceId}/label/${lastSelectedLabelId}`);
    }

    private async openLabelsMenu(event: MouseEvent) {
        event.stopPropagation();
        this._menuService.openComponent({
            component: "se-add-labels",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                configId: this._configId,
            },
        });
    }

    private openMoveMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-move-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                selectedRows: [{ id: this._configId, name: this._userState.selectedConfigName }],
                callback: () => this.configMoved(),
            },
        });
    }
    private async configMoved() {
        const result = await this._configService.api.getConfigSpaceName(this._configId);
        if (result.isOk) {
            this._spaceName = result.value.name;
            this._spaceId = result.value.id;
            this._lastSelectedLabelId = 0;
            this._userState.selectedSpaceId = this._spaceId;
            this._userState.selectedSpaceOrLabelChanged.triggerVoid();
            
            var newlink = window.location.pathname.replace(/\/space\/\d+/, `/space/${this._spaceId}`);
            window.history.replaceState({}, '', newlink);
            
            this._toasterService.showSuccess(`${this._userState.selectedConfigName} moved to ${this._spaceName} successfully.`);
        }
    }

    private openCopyMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-copy-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: {
                selectedRows: [{ id: this._configId, name: this._userState.selectedConfigName }],
                callback: () => this.configCopied(),
            },
        });
    }

    private configCopied() {
        this._toasterService.showSuccess(`${this._userState.selectedConfigName} copied successfully.`);
    }

    private async onDelete(event: MouseEvent) {
        event.stopPropagation();
        const result = await this._modalService.openConfirmDialogAsync({
            title: `Delete "${this._userState.selectedConfigName}"`,
            body: `Are you sure you want to delete "${this._userState.selectedConfigName}" and ALL related data?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._configService.api.deleteAsync(this._configId);
            if (result.isOk) {
                Router.go(`/space/${this._spaceId}/label/${this._lastSelectedLabelId}`);
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    render() {
        return html`
            <div class="breadcrumbs">
                <a href="/manage/spaces">Spaces</a>
                <span>/</span>
                <a href="/space/${this._userState.selectedSpaceId}">${this._spaceName}</a>
                <span>/</span>
                <a>${this._configName}</a>
            </div>
            <div class="top">
                <div class="top-header">
                    <div class="left-header" style="gap: 10px;">
                        <div class="title" style="display: flex; align-items: center;">
                            <h1 style="display: flex; align-items: baseline; gap: 8px;">
                                ${this._configName}
                                <span style="font-size: 0.6em; font-weight: normal;">
                                    ${this.getFileSize(this._fileSize)}
                                </span>
                            </h1>
                        </div>
                        <fa-icon 
                            class="icon" 
                            single-color="gray" 
                            fa-class="far fa-file"
                            style="margin-top: 8px;"
                        ></fa-icon>
                    </div>
                    <div class="right-header"></div>
                </div>
                <div style="display: flex; gap:2px;">
                    <se-secondary-button
                        @mousedown=${(event) => this.openLabelsMenu(event)}
                        ${htmlTitle("Apply labels", false, undefined, undefined, true)}
                        .customStyle=${{ padding: "1px 6px", margin: 0 }}
                        icon="far fa-tag"
                    ></se-secondary-button>
                    <se-secondary-button
                        @mousedown=${(event) => this.openMoveMenu(event)}
                        ${htmlTitle("Move to...", false, undefined, undefined, true)}
                        .customStyle=${{ padding: "1px 6px", margin: 0 }}
                        icon="far fa-truck"
                    ></se-secondary-button>
                    <se-secondary-button
                        @mousedown=${(event) => this.onDelete(event)}
                        ${htmlTitle(`Delete this shared file`, false, undefined, undefined, true)}
                        .customStyle=${{ padding: "1px 6px", margin: 0 }}
                        icon="far fa-trash-alt"
                        red-hover
                    ></se-secondary-button>
                </div>
            </div>
            <div style="display: flex; border-bottom: solid 1px gray; align-items: end; overflow-x:clip;">
                <span class="tabItems ${this._gridType === "info" ? `active` : ``}" @click=${this.goInfo}>Info</span>
                <div style="margin-right:10px"></div>
                <div style="margin-right:10px;margin-left: auto;"></div>
                ${this._canEditFile
                    ? html`
                          <se-primary-button
                              style="padding-bottom: 5px;"
                              text="Edit File"
                              @click="${() => this.editFile(this._configId, this._configName)}"
                          ></se-primary-button>
                      `
                    : html``}
                <se-primary-button 
                    style="padding-bottom: 5px;" 
                    text="Download File" 
                    @click="${() => this.downloadFile(this._configId)}"
                ></se-primary-button>
            </div>
            <div style="flex:1; min-height:0">
                ${this._gridType === "info" ? html`<se-shared-file-info .configId="${this._configId}"></se-shared-file-info>` : ``}
            </div>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
            gap: 20px;
            color: var(--color-primary);
            padding: 15px;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .tabItems {
            cursor: pointer;
            padding: 10px;
            position: relative;
        }
        .active {
            color: var(--color-purple);
            font-weight: 500;
        }
        .active::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .top-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a {
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover {
            text-decoration: underline;
        }
        h1 {
            margin: 10px 0px 5px;
            font-weight: 600;
        }
    `;
}
