import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { UserStatus } from "../../enums/user-status";
import { User } from "../../models/user";
import { AuthService } from "../../services/auth.service";
import { UserApi } from "../../services/user-api";
import { UserState } from "../../services/user.state";
import { BaseEditor } from "../editors/base-editor";
import { FormInputEditorElement } from "../editors/form/form-input-editor.element";
import { FormSelectEditorElement } from "../editors/form/form-select-editor.element";
import { FormToggleEditorElement } from "../editors/form/form-toggle-editor.element";
import "../components/cards/edit-form-card.element";
import "../components/cards/card-buttons.element";

@customElement("se-user-editor")
export class SeUserEditorElement extends LitElement {
    private _authService: AuthService;
    private _isNew = false;
    private _userId?: number;
    private _name: string;
    private _user: User;
    private _userApi: UserApi;
    private _userState: UserState;
    private _toasterService: ToasterService;

    private _accessOptions: { id: number; name: string }[] = [];
    private _authMethodOptions: { id: number; name: string }[] = [];

    @query("#name") private _nameEditor: FormInputEditorElement;
    @query("#email") private _email: FormInputEditorElement;
    @query("#password") private _password: FormInputEditorElement;
    @query("#accessLevel") private _accessLevel: FormSelectEditorElement;
    @query("#authMethod") private _authMethod: FormSelectEditorElement;
    //@query("#isMfaRequired") private _isMfaRequiredEditor: FormToggleEditorElement;

    @state() private _authMethodId = 1;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userApi = container.resolve(UserApi);
        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            document.location = "/login";
            return commands.prevent();
        }
        if (location.params.userId) {
            this._userId = parseInt(location.params.userId.valueOf() as string);
            this._isNew = false;
            const res = await this._userApi.getAsync(this._userId);
            if (res.isOk) {
                if (res.value.status == UserStatus.Deleted && !this._authService.isSE4Admin) {
                    this._toasterService.showError("This user is deleted.");
                    return commands.prevent();
                }
                this._user = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._user = new User();
        }

        this._accessOptions = [
            //{ id: 400, name: "Read Only" },
            { id: 300, name: "User" },
            { id: 200, name: "Administrator" },
        ];

        if (this._authService.isSE4Admin) {
            this._accessOptions.push({ id: 100, name: "SE4 Administrator" });
        }

        this._authMethodOptions = [
            { id: 1, name: "Local" },
            /*{ id: 2, name: "Google" },
            { id: 3, name: "Microsoft" },*/
        ];
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            this._user.id = this._userId;
            this._user.email = this._email.liveValue;
            this._user.name = this._nameEditor.liveValue;
            this._user.authenticationService = Number(this._authMethod.liveValue);
            if (this._user.authenticationService === 1) {
                this._user.password = this._password?.liveValue;
            }
            this._user.accessLevel = Number(this._accessLevel.liveValue);
            this._user.ownerOrganizationId = this._authService.user.organizationId;
            // if (!this._isNew) {
            //     this._user.isMfaRequired = this._isMfaRequiredEditor.liveValue;
            // }

            const res = this._isNew
                ? await this._userApi.createAsync(this._user)
                : await this._userApi.updateAsync(this._userId, this._user);
            if (res.isOk) {
                await this._userState.refreshUserSpacesAsync();
                this._userState.userSpacesChanged.triggerVoid();
                history.back();
            } else {
                alert(res.err.message);
            }
        }
    }

    cancel() {
        history.back();
    }

    async authMethodChanged() {
        this._authMethodId = Number(this._authMethod.liveValue);
    }

    //fullname , email, temp password, and access level
    render() {
        return html`
            <se-edit-form-card
                title="${this._isNew ? "Create" : "Edit"} User"
            >
                <se-form-input-editor
                    id="name"
                    name="name"
                    type="text"
                    label="Full Name"
                    labelPosition="left"
                    input-type="text"
                    required
                    size="30"
                    .value=${this._user?.name}
                ></se-form-input-editor>
                <se-form-input-editor
                    id="email"
                    name="email"
                    type="text"
                    label="Email"
                    input-type="text"
                    required
                    size="30"
                    .value=${this._user?.email}
                ></se-form-input-editor>

                <se-form-select-editor
                    class="inputEditor"
                    required
                    id="authMethod"
                    name="authMethod"
                    type="text"
                    label="Authentication Method"
                    .value=${String(this._user?.authenticationService || 1)}
                    .options=${this._authMethodOptions}
                    width="100%"
                    @valueChanged=${this.authMethodChanged}
                ></se-form-select-editor>
                ${this._authMethodId === 1
                    ? html`
                            ${this._isNew
                                ? html`<se-form-input-editor
                                    id="password"
                                    name="password"
                                    input-type="password"
                                    label="Temp Password"
                                    input-type="text"
                                    required
                                    size="30"
                                ></se-form-input-editor>`
                                : html`<se-form-input-editor
                                    id="password"
                                    name="password"
                                    placeholder="(Unchanged)"
                                    input-type="password"
                                    label="Temp Password"
                                    input-type="text"
                                    size="30"
                                ></se-form-input-editor>`}
                        `
                    : html``}

                    <se-form-select-editor
                        class="inputEditor"
                        required
                        id="accessLevel"
                        name="accessLevel"
                        type="text"
                        label="Group"
                        .value=${String(this._user?.accessLevel || 300)}
                        labelPosition="left"
                        .options=${this._accessOptions}
                        width="100%"
                    ></se-form-select-editor>

                </se-edit-form-card>

                <se-card-buttons>
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Create" : "Save"} User"
                    ></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </se-card-buttons>
        `;
    }

    
    // ${this._isNew
    //     ? html``
    //     : html`
    //         <se-form-toggle-editor
    //             id="isMfaRequired"
    //             .value="${this._user?.isMfaRequired ?? false}"
    //             name="isMfaRequired"
    //             label="Multi-factor authentication required"
    //             labelPosition="left"
    //         ></se-form-toggle-editor>
    //         `}

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
}
