import "element-internals-polyfill";
import { css, html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { styleMap } from "lit/directives/style-map.js";
import { BaseEditor } from "../base-editor";

@customElement("se-form-select-editor")
export class FormSelectEditorElement extends LitElement implements BaseEditor {
    @property() value: string;

    get liveValue() {
        return this._inputElement?.value ? this._inputElement?.value : undefined;
    }

    @property({ attribute: false, type: Array }) options?: { id: string | number; name: string }[];
    @property({ attribute: false, type: Array }) textOptions?: string[];

    @property() label?: string;
    @property() labelHelp?: string;

    @property({ type: Number }) step?: number;
    @property({ type: Number }) size?: number;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property({ attribute: "input-width" }) inputWidth?: string;
    @property() min?: string;
    @property() max?: string;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property({ attribute: "editor-size" }) editorSize: "small" | "nomal";
    @property() gap;

    @query("select") private _inputElement: HTMLSelectElement;
    
    constructor() {
        super();
    }

    updateValue(event) {
        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
        this.dispatchEvent(
            new CustomEvent("valueChanged", {
                bubbles: true,
                composed: true,
                detail: { editor: this, value: this.liveValue ? this.liveValue : undefined, oldValue: this.value },
            })
        );
    }
    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    firstUpdated() {}

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    select(value: string) {
        this._inputElement.value = value;
    }

    render() {
        return html`
            <div class="form-row">
                <label for="${this.name}">
                    <div><span>${this.label}</span></div>
                    <div class="help">${this.labelHelp}</div>
                </label>
                <div class="input-container">
                    <select
                        class="select-settings"
                        name="${this.name}"
                        @change="${this.updateValue}"
                        min="${ifDefined(this.min)}"
                        max="${ifDefined(this.max)}"
                        maxlength="${ifDefined(this.maxlength)}"
                        minlength="${ifDefined(this.minlength)}"
                        step="${ifDefined(this.step)}"
                        size="${ifDefined(this.size)}"
                        pattern="${ifDefined(this.pattern)}"
                        ?required="${this.required}"
                        ?disabled="${this.disabled}"
                    >
                        ${this.placeholder ? html`<option value="" disabled selected hidden>${this.placeholder}</option>` : ``}
                        ${this.textOptions
                            ? this.textOptions.map(
                                (option) => html` <option value="${option}" ?selected=${this.value === option}>${option}</option> `
                            )
                            : this.options?.map(
                                (option) => html`
                                    <option value="${option.id}" ?selected=${this.value === (option.id?.toString() ?? "")}>
                                        ${option.name}
                                    </option>
                                `
                            )}
                    </select>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
        }
        :host([hidden]) {
            display: none;
        }
        select {
            font: var(--font-input);
        }
        .select-settings {
            font: var(--font-input);
            border-radius: 8px;
            border: 1px solid var(--color-gray-4);
            padding: 10px 15px;
            background: var(--color-background);
            width: 100%;
}
        label {
            font: var(--font-input-label);
        }
        input:disabled + label {
            color: gray;
        }
        input.invalid {
            outline: 2px solid pink;
        }
        .form-row {
            padding: 10px;
            align-items: center;
            display: flex;
            transition: background 0.15s;
            border-bottom: 1px solid rgb(240, 236, 243);
        }
        .form-row label {
            font-weight: 400;
            display: inline;
            margin-bottom: 0px;
            width: 50%;
            flex-shrink: 0;
        }
        .input-container {
            display: flex;
            flex: 1 1 0%;
            flex-direction: column;
            position: relative;
            max-width: 100%;
        }
        label {
            font: var(--font-input-label);
            margin-bottom: 4px;
        }

        label .help {
            color: rgb(128, 112, 143);
            font-size: 12px;
            margin-top: 4px;
            line-height: 1.4;
        }
    `;
}
