import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { DataGridColumn } from "../data-grid-template";
import { SeDataGrid } from "../data-grid.element";
import { MenuItem } from "../menu.element";
import { CheckboxEditorElement } from "../../editors/checkbox-editor.element";
import { ApiKey } from "../../../models/api-key";
import { container } from "tsyringe";

export interface ApiKeysGridConfig {
    onEdit: (row: ApiKey) => void;
    onDelete: (row: ApiKey & { selected: boolean }) => Promise<void>;
    onDeleteMany: (keyIds: number[]) => Promise<void>;
    onRefresh: () => void;
    onNew: () => void;
}

@customElement("se-api-keys-grid")
export class SeApiKeysGridElement extends LitElement {
    @state() private _data: ApiKey[] = [];
    @state() private _hasSelectedRows = false;

    private _columns: DataGridColumn[] = [];
    private _config: ApiKeysGridConfig;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    constructor() {
        super();
    }

    /**
     * Sets the API key data for the grid
     * @param value Array of API keys to display
     */
    public set data(value: ApiKey[]) {
        this._data = value;
        this.requestUpdate();
    }

    /**
     * Sets the configuration for the grid including callback functions
     * @param value Configuration object containing event handlers
     */
    public set config(value: ApiKeysGridConfig) {
        this._config = value;
        this.loadColumns();
    }

    /**
     * Generates the context menu items for a grid row
     * @param row The API key row data
     * @param col The column definition
     * @returns Array of menu items
     */
    private menu(row: ApiKey, col: DataGridColumn): MenuItem[] {
        return [
            { text: "Edit", action: () => this._config.onEdit(row) },
            { text: "-" },
            { text: "Delete", action: () => this._config.onDelete(row as ApiKey & { selected: boolean }) },
        ];
    }

    /**
     * Initializes the grid columns with their definitions
     */
    private loadColumns() {
        this._columns = [
            { field: "description", title: "Name" },
            { field: "displayToken", title: "Key", actionLink: (row) => this._config.onEdit(row) },
            {
                field: "expiry",
                title: "Expiry",
                template: (row) => html`${new Date(row.expiry).toLocaleDateString()} ${new Date(row.expiry).toLocaleTimeString()}`,
            },
            {
                field: "created",
                title: "Created",
                template: (row) => html`${new Date(row.created).toLocaleDateString()} ${new Date(row.created).toLocaleTimeString()}`,
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
    }

    /**
     * Handles the select all checkbox state change
     * @param evt Checkbox change event
     */
    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    /**
     * Handles grid selection change events
     * @param evt Selection change event
     */
    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    /**
     * Updates the select all checkbox state based on grid selection
     */
    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    /**
     * Handles bulk deletion of selected API keys
     * @param event Mouse event from delete button
     */
    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();
        const keyIds = this._dataGrid.selectedRows.map((p) => p.id as number);
        await this._config.onDeleteMany(keyIds);
        this._selectAll.value = false;
        this._hasSelectedRows = false;
    }

    /**
     * Renders the component template
     * @returns Rendered template
     */
    render() {

        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>

                        ${
                            this._hasSelectedRows
                                ? html`
                                      <se-secondary-button
                                          @mousedown=${(event) => this.onDeleteMany(event)}
                                          ${htmlTitle("Delete Selected")}
                                          .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                          icon="far fa-trash-alt"
                                      ></se-secondary-button>
                                  `
                                : html`
                                      <se-secondary-button
                                          @click=${() => this._config.onRefresh()}
                                          ${htmlTitle("Refresh")}
                                          .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                          icon="far fa-redo"
                                      ></se-secondary-button>
                                  `
                        }
                    </div>
                    <div style="display: flex;gap:20px; font-size:0.95em;align-items: baseline">                            
                        <se-primary-button text="New API Key" @click="${() => this._config.onNew()}"></se-primary-button>                                                
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    placeholder="No API keys available."
                ></se-data-grid>                    
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: hidden;
            padding: 10px 10px 10px 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
    `;
} 