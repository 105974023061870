import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { customElement, query } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ApiKey } from "../../models/api-key";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationService } from "../../services/organization.service";
import { UserService } from "../../services/user.service";
import { BaseEditor } from "../editors/base-editor";
import { DateTimeEditorElement } from "../editors/datetime-editor.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";

@customElement("se-api-key-editor")
export class SeApiKeyEditorElement extends LitElement {
    private _authService: AuthService;
    private _modalService: ModalDialogService;
    private _organizationService: OrganizationService;
    private _isNew = false;
    private _apiKeyId?: number;
    private _userId?: number;
    private _apiKey?: ApiKey;
    private _userService: UserService;
    private _toasterService: ToasterService;


    @query("#description") private _descriptionEditor: TextAreaEditorElement;
    @query("#expiry") private _expiryEditor: DateTimeEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._organizationService = container.resolve(OrganizationService);
        this._userService = container.resolve(UserService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.apiKeyId) {
            this._apiKeyId = parseInt(location.params.apiKeyId.valueOf() as string);
            this._isNew = false;
            //return this._spaceApi.getAsync(this._spaceId).then(space => this._space = space).catch(err => this.error(err));
            const res = await this._userService.api.getApiKeyAsync(this._apiKeyId);
            if (res.isOk) {
                this._apiKey = res.value;
            } else {
                return commands.prevent();
            }
        } else if (location.params.userId) {
            this._userId = parseInt(location.params.userId.valueOf() as string);
            this._isNew = true;
            this._apiKey = new ApiKey();
            const now = new Date();
            const d = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
            this._apiKey.expiry = d.toISOString();
        } else {
            return commands.prevent();
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    /**
     * Save on the server
     * 
     * @param ev
     */
    async saveAsync(ev) {
        ev.preventDefault();
        if (this.reportValidity()) {

            //save
            if (this._isNew) {
                await this.createNewApiKey();
            } else {
                await this.updateApiKey();
            }
        }
    }

    /**
    * Create a new key
    */
    async createNewApiKey() {
        const res = await this._userService.api.createApiKeyAsync({
            userId: this._userId,
            description: this._descriptionEditor.liveValue,
            expiry: this._expiryEditor.liveValue.toISOString(),
        });

        if (res.isOk) {
            const result = await this._modalService.openInfoDialogAsync({
                title: "Save Api Key",
                body: `Please copy the key to a secure location because this will be the only time that it is visible.
                  <p>
                    <button
                        type="button"
                        onclick="navigator.clipboard.writeText('${res.value.token}')"
                        title="Copy to Clipboard"
                        icon="far fa-copy"
                        class="copy-button"
                    >
                    <fa-icon
                              style="font-size:0.9em"
                              fa-class="far fa-copy"
                              single-color=""
                              scale="0.9"
                          ></fa-icon>
                    </button>
                    <strong><span id="copyValue">${res.value.token}</span></strong>
                  </p>`,
            });
            if (result.isSave) {
                history.back();
            }
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    /**
     * Update an existing key on the server
     */
    async updateApiKey() {

        const res = await this._userService.api.updateApiKeyAsync(this._apiKeyId, {
            id: this._apiKeyId,
            userId: this._apiKey.userId,
            description: this._descriptionEditor.liveValue,
            expiry: this._expiryEditor.liveValue.toISOString(),
        });


        if (res.isOk) {
            history.back();
        } else {
            this._toasterService.showNetworkError(res.err);
        }

    }

    cancel() {
        history.back();
    }

    render() {
        const d = new Date(this._apiKey.expiry);
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? "Add" : "Edit"} API key</div>
                <div class="scroll-container">
                    <se-textarea-editor
                        id="description"
                        name="description"
                        type="text"
                        label="Description"
                        labelPosition="top"
                        width="400px"
                        size="50"
                        .value=${this._apiKey.description ?? ""}
                    ></se-textarea-editor>
                    <se-datetime-editor
                        id="expiry"
                        type="datetime-local"
                        name="expiry"
                        label="Expiry"
                        labelPosition="top"
                        required
                        .value=${d}
                    ></se-datetime-editor>
                </div>
                <div class="savePanel">
                    <se-primary-button @click="${this.saveAsync}" text="${this._isNew ? "Add" : "Save"} Key"></se-primary-button>
                    <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
        .copy-button {
            margin-left: 8px;
            padding: 4px 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            background: white;
            cursor: pointer;
        }

        .copy-button:hover {
            background: #f0f0f0;
        }

        .copy-button i {
            color: #666;
        }
    `;
}
