import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import "../components/primary-button.element";
import "../components/secondary-button.element";
import { BaseEditor } from "../editors/base-editor";
import "../editors/input-editor.element";
import "../components/cards/edit-form-card.element";
import "../components/cards/card-buttons.element";
import { FormInputEditorElement } from "../editors/form/form-input-editor.element";

@customElement("se-change-password")
export class ChangePasswordElements extends LitElement {
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _email: string;

    @query("#currentPassword") private _currentPasswordEditor: FormInputEditorElement;
    @query("#password") private _passwordEditor: FormInputEditorElement;
    @query("#confirmPassword") private _comparePasswordEditor: FormInputEditorElement;

    @state() private _hasChanged = false;

    constructor() {
        super();

        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
        return undefined;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {}

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {}

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async save(ev) {
        ev.preventDefault();
        if (this.reportValidity()) {
            this._hasChanged = false;
            this._email = this._authService.user.email;
            await this._authService
                .ChangePassword(this._currentPasswordEditor.liveValue, this._passwordEditor.liveValue)
                .then(() => this.success())
                .catch((err) => this.error(err));
        }
    }

    private async success() {
        this._toasterService.showSuccess("Password successfully updated.");
        this._authService.logout();
        this._authService
            .loginAsync(this._email, this._passwordEditor.liveValue, true)
            .then(() => this.reLogin())
            .catch((err) => this.error(err));
    }

    private reLogin() {
        history.back();
    }

    private async cancel(ev) {
        ev.preventDefault();
        history.back();
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        } else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    render() {
        return html`
            <se-edit-form-card title="Edit">
                <se-form-input-editor
                    id="currentPassword"
                    name="currentPassword"
                    type="text"
                    label="Current Password"
                    labelHelp="Enter your current password"
                    input-type="password"
                    required
                    size="30"
                ></se-form-input-editor>
                
                <se-form-input-editor
                    id="password"
                    name="password"
                    type="text"
                    label="New Password"
                    labelHelp="Enter your new password"
                    input-type="password"
                    required
                    size="30"
                    @editorChanged=${this.passwordChanged}
                ></se-form-input-editor>
                
                <se-form-input-editor
                    id="confirmPassword"
                    name="confirmPassword"
                    type="text"
                    label="Confirm Password"
                    labelHelp="Re-enter your new password"
                    input-type="password"
                    required
                    size="30"
                    @editorChanged=${this.passwordChanged}
                ></se-form-input-editor>
            </se-edit-form-card>
            
            <se-card-buttons>
                <se-primary-button
                    @click="${this.save}"
                    ?disabled="${!this._hasChanged}"
                    text="Save"
                ></se-primary-button>
            </se-card-buttons>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
}
