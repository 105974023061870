export enum BillingType {
    RunUsage = 1, //This is for Run Time cost
    ExportDataBandwidthUsage = 2, //This is for Data Transfer cost through Export.
    TotalPages = 3,
    DynamicPages = 4,
    TotalRequests = 5,
    ExportedData = 6, //This is to cost the exported number of data rows (export CSV, Sheets, Snowflake..etc)
    InputCount = 7, //This is to cost the Input Params passed in Agent Config/Run.
    TrafficData = 8, //This is to cost of the proxy data usage

}