import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { UserStatus } from "../../enums/user-status";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";

@customElement("se-organization-users")
export class SeOrganizationUsersElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn = "name";
    private _sortOrder = -1;
    @state() private _data: any[];

    @state() private _hasSelectedRows = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);

        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            ...(row.status == 3 ? [] : [{ text: "Edit", action: this.editUser.bind(this, row, col) }] ), // hide "edit" if user is deleted
            { text: "-" },
            {
                text: row.status == 3 ? "Restore Access" : "Delete",
                action:
                    row.status == 3
                          ? this.restoreUserAccessAsync.bind(this, row, col)
                          : this.deleteUserAsync.bind(this, row, col),
            },
            { text: "-" },
            {
                text: "Manage API Keys",
                action: this.manageApiKeys.bind(this, row.id),
                hidden: row.userAccessLevel === AccessLevel.SE4Administrator,
            },
            ...((!row.isEmailVerified && row.status !== UserStatus.Deleted) ? [
                { text: "-" },
                {
                    text: "Resend Verification Email",
                    action: () => this.resendInvite(row),
                }
            ] : []),
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    private editUser(row: any) {
        Router.go(`/org/users/edit/${row.id}`);
    }

    private async deleteUserAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete User",
            body: `Are you sure you want to delete ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._userService.api.deleteAsync(row.id);
            if (result.isOk) {
                //const index = this._data.indexOf(row);
                //this._data.splice(index, 1);
                //if (row.selected)
                //    this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async restoreUserAccessAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Restore User Access",
            body: `Are you sure you want to restore ${row.name}'s access?`,
            saveCaption: "Restore",
        });
        if (result.isSave) {
            const result = await this._userService.api.restoreAccessAsync(row.id);
            if (result.isOk) {
                //const index = this._data.indexOf(row);
                //this._data.splice(index, 1);
                //if (row.selected)
                //    this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    firstUpdated() {}

    private manageApiKeys(userId: number) {
        Router.go(`/org/users/api-keys/${userId}`);
    }

    private async loadData() {
        this._columns = [
            {
                field: "name",
                title: "Full Name",
                template: (row, col) => html`
                    <a href="javascript:;" 
                       @click=${(e) => { 
                           e.stopPropagation(); 
                           if (Number(row.status) !== UserStatus.Deleted && row.isEmailVerified) 
                               col.action(row, col);
                       }}
                       style="text-decoration: none; color: var(--color-purple);"
                    >
                        ${row.name || '(Invited)'}
                    </a>`,
                action: (row) => this.editUser(row),
                sortable: true,
            },
            {
                field: "email",
                title: "Email",
                sortable: true,
            },
            {
                field: "status",
                title: "Status",
                sortable: true,
                template: (row, col) =>
                    html`${choose(
                        Number(row.status),
                        [
                            [undefined || null, () => html`N/A`],
                            [UserStatus.Active, () => 
                                row.isEmailVerified 
                                    ? html`<span style="color:lime">Active</span>`
                                    : html`<span style="color:orange">Invited</span>`
                            ],
                            [UserStatus.Disabled, () => html`<span style="color:black">Disabled</span>`],
                            [UserStatus.Deleted, () => html`<span style="color:red">Deleted</span>`],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "apiKeyCount",
                title: "API Keys",
                align: "center",
                template: (row) => (row.userAccessLevel !== AccessLevel.SE4Administrator ? html`${row.apiKeyCount}` : html``),
                actionLink: (row) => this.manageApiKeys(row.id),
            },
            {
                field: "created",
                title: "Created",
                align: "center",
                sortable: true,
                template: (row, col) => html`${new Date(row.created).toLocaleDateString()} ${new Date(row.created).toLocaleTimeString()}`,
            },
            //{
            //    field: 'userAccessLevel', title: 'Access Level select', align: 'center', sortable: true, template: (row, col) =>
            //        (this._authService.isAdmin) ? html`
            //        <select @change=${this.saveAsync.bind(this, row)} style="font:var(--font-input);width:100%;" @valueChanged=${this.saveAsync.bind(this, row)}>
            //            ${row.userAccessLevel == AccessLevel.SE4Administrator ? html`<option selected value="100">SE4 Administrator</option>` : html`<option value="100">SE4 Administrator</option>`}
            //            ${row.userAccessLevel == AccessLevel.OrgAdministrator ? html`<option selected value="100">Org Administrator</option>` : html`<option value="200">Org Administrator</option>`}
            //            ${row.userAccessLevel == AccessLevel.User ? html`<option selected value="100">User</option>` : html`<option value="300">User</option>`}
            //            ${row.userAccessLevel == AccessLevel.ReadOnly ? html`<option selected value="100">Read Only</option>` : html`<option value="400">Read Only</option>`}
            //        </select>` : html`${choose(row.userAccessLevel, [
            //            [undefined || null, () => html`N/A`],
            //            [AccessLevel.Org Administrator, () => html`<span style='color:red'>Org Administrator</span>`],
            //            [AccessLevel.SE4Administrator, () => html`<span style='color:red'>SE4 Administrator</span>`],
            //            [AccessLevel.User, () => html`<span style='color:blue'>User</span>`],
            //            [AccessLevel.ReadOnly, () => html`<span style='color:black'>Read Only</span>`]],
            //            () => html`${row.userAccessLevel}`)}`
            //},

            /*{ field: 'organizationName', title: 'Organization' },*/
            {
                field: "userAccessLevel",
                title: "Group",
                sortable: true,
                template: (row, col) =>
                    html`${choose(
                        row.userAccessLevel,
                        [
                            [undefined || null, () => html`N/A`],
                            [AccessLevel.OrgAdministrator, () => html`<span style="color:red">Org Administrator</span>`],
                            [AccessLevel.SE4Administrator, () => html`<span style="color:red">SE4 Administrator</span>`],
                            [AccessLevel.User, () => html`<span style="color:blue">User</span>`],
                            [AccessLevel.ReadOnly, () => html`<span style="color:black">Read Only</span>`],
                        ],
                        () => html`${row.userAccessLevel}`
                    )}`,
            },
            //{
            //    title: 'Actions', align: 'center', template: (row, col) => html`
            //<se-secondary-button @mousedown=${this.deleteUserAsync.bind(this, row, col)} .customStyle=${{ padding: '1px 6px', margin: 0 }} icon="far fa-trash-alt"></se-secondary-button>`
            //},
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
        //const result = await this._userState.getUserSpacesAsync(this._pageIndex, this._recordsPerPage);

        this._isLoading = true;
        try {
            const result = await this._organizationService.getAllOrganizationUsersAsync(
                this._pageIndex,
                this._recordsPerPage,
                this._sortColumn,
                this._sortOrder
            );
            if (result.isOk) {
                this._data = result.value.orgUsers !== null ? result.value.orgUsers : [];
                this._totalRecordCount = result.value.totalRecordCount;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== (this._data?.length ?? 0)) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private async sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        await this._organizationService.getAllOrganizationUsersAsync(
            this._pageIndex,
            this._recordsPerPage,
            this._sortColumn,
            this._sortOrder
        );
        //await this._userState.refreshUserSpacesAsync(this._pageIndex, this._recordsPerPage, sortColumn, sortOrder);
        this.loadData();
        //this._userState.userSpacesChanged.triggerVoid();

        //this._data.sort((a, b) => a[sortColumn] < b[sortColumn] ? -sortOrder : (a[sortColumn] > b[sortColumn] ? sortOrder : 0));
    }

    private async onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;

        await this._organizationService.getAllOrganizationUsersAsync(
            this._pageIndex,
            this._recordsPerPage,
            this._sortColumn,
            this._sortOrder
        );
        this.loadData();
        //this._userState.userSpacesChanged.triggerVoid();
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete User",
            body: `Are you sure you want to delete ${
                count === 1 ? `${this._dataGrid.selectedRows[0].name} and ALL its content` : `${count} user and ALL their content`
            }?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const userIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._userService.api.deleteAllAsync(userIds);
            if (result.isOk) {
                await this._organizationService.getAllOrganizationUsersAsync(
                    this._pageIndex,
                    this._recordsPerPage,
                    this._sortColumn,
                    this._sortOrder
                );
                this.loadData();
                //this._userState.userSpacesChanged.triggerVoid();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    async saveAsync(row, event: any) {
        const user = {
            id: row.id,
            name: row.name,
            email: row.email,
            organizationName: row.status,
            organizationId: row.created,
            accessLevel: Number(event.target.value),
            isMfaRequired: row.isMfaRequired,
            authenticationService: row.authenticationService,
        };
        const res = await this._userService.api.updateAsync(user.id, user);
        if (res.isOk) {
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    newUser() {
        Router.go('/org/users/edit');
    }

    private async resendInvite(row: any) {
        const result = await this._userService.api.resendInviteAsync(row.email);
        if (result.isOk) {
            this._toasterService.showSuccess("Invitation email resent successfully");
        } else {
            this._toasterService.showError(result.err.message || "Failed to resend invitation");
        }
    }

    private inviteUser() {
        Router.go('/org/users/invite');
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${!this._data || this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                    <div class="right-header">
                        <se-primary-button 
                            text="Invite User" 
                            @click="${this.inviteUser}"
                        ></se-primary-button>
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    .defaultSorted=${this._sortColumn}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No users available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
                    <se-pagination .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
