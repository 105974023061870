import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { UserService } from "../../services/user.service";
import { ApiKey } from "../../models/api-key";
import { ApiKeysGridConfig } from "../components/api-keys/api-keys-grid.element";
import { SeApiKeysGridElement } from "../components/api-keys/api-keys-grid.element";
import './api-key-editor.element';

@customElement("se-api-keys")
export class SeApiKeysElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _userService: UserService;

    @state() private _data: ApiKey[] = [];
    @query("se-api-keys-grid") private _grid: SeApiKeysGridElement;

    private _userId: number;
    private _userName: string;
    private _gridConfig: ApiKeysGridConfig;

    /**
     * Initializes the component and sets up grid configuration with routing and action handlers
     */
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._userService = container.resolve(UserService);

        this._gridConfig = {
            onEdit: (row) => Router.go(`/org/users/api-keys/${this._userId}/edit/${row.id}`),
            onDelete: this.deleteKeyAsync.bind(this),
            onDeleteMany: this.deleteAllKeysAsync.bind(this),
            onRefresh: this.loadData.bind(this),
            onNew: () => Router.go(`/org/users/api-keys/${this._userId}/new`)
        };
    }

    /**
     * Lifecycle callback when element is added to DOM. Resets label selection and loads initial data
     */
    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }

    /**
     * Router guard that checks admin permissions and validates user ID parameter
     * @param location - Current router location
     * @param commands - Router navigation commands
     * @param router - Router instance
     * @returns Redirect command if unauthorized or invalid params
     */
    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        if (location.params.userId) {
            this._userId = parseInt(location.params.userId.valueOf() as string);
        } else {
            return commands.prevent();
        }
    }

    /**
     * Handles deletion of a single API key after confirmation
     * @param row - API key data with selection state
     */
    private async deleteKeyAsync(row: ApiKey & { selected: boolean }) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete API Key",
            body: `Are you sure you want to delete ${row.displayToken}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._userService.api.deleteApiKeyAsync(this._userId, row.id);
            if (result.isOk) {
                await this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    /**
     * Handles bulk deletion of multiple API keys after confirmation
     * @param keyIds - Array of API key IDs to delete
     */
    private async deleteAllKeysAsync(keyIds: number[]) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete API Keys",
            body: `Are you sure you want to delete ${keyIds.length === 1 ? "this key" : `${keyIds.length} API keys`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._userService.api.deleteAllApiKeysAsync(this._userId, keyIds);
            if (result.isOk) {
                await this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    /**
     * Fetches and updates the list of API keys for the current user
     */
    private async loadData() {
        const result = await this._userService.api.getApiKeysAsync(this._userId);
        if (result.isOk) {
            this._data = result.value.userApiKeys;
            this._userName = result.value.userName;
            if (this._grid) {
                this._grid.data = this._data;
            }
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    /**
     * Renders the API keys grid component
     * @returns Rendered template
     */
    render() {
        return html`
            <se-api-keys-grid .data=${this._data} .config=${this._gridConfig}></se-api-keys-grid>
        `;
    }

    static styles = css`
        :host {
            display: block;
            height: 100%;
        }
    `;
}
