import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { Router } from "@vaadin/router";
import { ConfigType } from "../../enums/config-type";
import { container } from "tsyringe";
import { UserState } from "../../services/user.state";
import { UserApi } from "../../services/user-api";
import { ServerService } from "../../services/server.service";
import { AuthService } from "../../services/auth.service";
import { ToasterService } from "se-shared/services/toaster.service";

@customElement("se-welcome")
export class SeWelcomeElement extends LitElement {
    private _userState: UserState;
    private _userApi: UserApi;
    private _serverService: ServerService;
    private _authService: AuthService;
    private _toasterService: ToasterService;

    constructor() {
        super();
        this._userState = container.resolve(UserState);
        this._userApi = container.resolve(UserApi);
        this._serverService = container.resolve(ServerService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -2;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }
    
    private async openEditor(): Promise<boolean> {
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        const response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return false;
        } else {
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);

        const params = {
            redirectUrl: window.location.href,
            apiUrl: window.location.protocol + "//" + hostApi + "/api",
            webApiUrl: window.location.origin + "/api",
            token: this._authService.token,
            isInternalOrg: this._authService.user.organizationName === "Sequentum",
            editorServerHost: editorServer,
            orgId: this._authService.user.organizationId,
            isProxyRequired: this._authService.orgSettings.isProxyRequired,
            isDevTools: this._authService.orgSettings.isAllowDevTools,
            configType: ConfigType.Agent
        };

        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return true;
    }

    private async hideWelcome() {
        await this._userApi.hideWelcomeScreenAsync();
        Router.go('/');
    }

    render() {
        return html`
            <div class="welcome-body">    
                <div class="welcome-container">
                    <div class="video-container">
                        <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1037548666?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Sequentum Cloud - How to build your first agent"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    
                    <h1>Welcome to Sequentum Cloud!</h1>
                    <p>Build an agent to automate anything you need - collect and transform web data, reuse templates, and deliver data to the cloud. Choose one of the following options to get started:</p>
        
                    <ul>
                        <li>
                            <p><a href="https://cloudsupport.sequentum.com/ct/" target="_blank" rel="nofollow">Video Demonstration</a> (10 mins) - Watch an expert create a simple agent.</p>
                        </li>
                        <li>
                            <p><a href="https://cloudsupport.sequentum.com/scm/build-your-first-agent-overview" target="_blank" rel="nofollow">Quick Tour</a> (5 mins) - Build and test a basic agent, publish it, and review the output.</p>
                        </li>
                        <li>
                            <p><a href="https://cloudsupport.sequentum.com/scm/building-your-first-agent" target="_blank">Build Your First Agent</a> (10 mins) - Follow a step-by-step guide to create a simple agent.</p>
                        </li>
                        <li>
                            <p><a href="https://cloudsupport.sequentum.com/scm/building-an-advanced-agent" target="_blank">Build an Advanced Agent</a> (30 mins) - Dive deeper and learn how to extract data from more complex sources.</p>
                        </li>
                    </ul>
        
                    <p>Ready to dive in? Launch the editor to get started with your first agent!</p>
                    <div class="button-container">
                        <se-primary-button
                            text="Create an Agent"
                            @click=${this.openEditor}
                        ></se-primary-button>
                        <se-primary-button
                            text="Don't show this anymore"
                            @click=${this.hideWelcome}
                        ></se-primary-button>
                    </div>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            height: 100%;
        }
        .welcome-body {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 15px;
        }
        .welcome-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: left;
            align-self: center;
            gap: 20px;
            padding: 40px;
            width: 70%; 
            max-width: 1000px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
        }
        .button-container {
            display: flex;
            gap: 10px;
            align-self: center;
        }
        .video-container {
            position: relative;
            width: 100%;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
        }
        .video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
            color: var(--color-primary);
        }
        p {
            margin: 0;
        }
        ul {
            margin: 0.5rem 0;
        }
        li {
            margin: 0.5rem 0;
            color: #666;
        }
        a {
            color: var(--color-secondary);
            text-decoration: none;
            font-weight: 500;
        }
        a:hover {
            text-decoration: underline;
        }
    `;
} 