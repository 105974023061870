import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { SeDataGrid } from "../components/data-grid.element";
import { DataGridColumn } from "../components/data-grid-template";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { choose } from "lit/directives/choose.js";
import { isActive, ServerKeysStatus } from "../../enums/server-keys-status";
import { ServerKeysService } from "../../services/server-keys.service";

@customElement("se-server-keys")
export class SeServeKeysElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    public _serverKeysService: ServerKeysService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _name = "null";
    private _sortColumn = "Created";
    private _sortOrder = -1;
    private _status = -1;
    @state() private _data: any[] = [];

    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    private _menuService: MenuService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._serverKeysService = container.resolve(ServerKeysService);
        this._menuService = container.resolve(MenuService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            { text: "Edit", action: this.editServerKeyAsync.bind(this, row, col), hidden: !isActive(row.status) },
            { text: "Revoke", action: this.revokeServerKeyAsync.bind(this, row, col), hidden: row.status === ServerKeysStatus.Revoked },
            { text: "Delete", action: this.deleteServerKeyAsync.bind(this, row, col) },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    private async revokeServerKeyAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Revoke ServerKey",
            body: `Are you sure you want to revoke - ${row.clientName}?`,
            saveCaption: "Revoke",
        });
        if (result.isSave) {
            const result = await this._serverKeysService.api.revokeServerKeyAsync(row.id);
            if (result.isOk) {
                //const index = this._data.indexOf(row);
                //this._data.splice(index, 1);
                this._toasterService.showSuccess(row.clientName + " revoked successfully.", 10000);
                if (row.selected) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async deleteServerKeyAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete ServerKey",
            body: `Are you sure you want to delete - ${row.clientName}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._serverKeysService.api.deleteAsync(row.id);
            if (result.isOk) {
                this._toasterService.showSuccess(row.clientName + " is Deleted successfully.", 10000);
                const index = this._data.indexOf(row);
                this._data.splice(index, 1);
                if (row.selected) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async editServerKeyAsync(row: any, col: DataGridColumn) {
        Router.go("/manage/admin/edit/server-key/" + row.id);
    }

    firstUpdated() {}

    private async loadData() {
        this._columns = [
            { field: "id", title: "Id" },
            { field: "clientId", title: "Client Id" },
            { field: "clientName", title: "Name", cellStyle: { textDecoration: "underline" }, action: this.editServerKeyAsync.bind(this) },
            {
                field: "status",
                title: "Status",
                sortable: true,
                align: "center",
                template: (row, col) =>
                    html`${choose(
                        row.status,
                        [
                            [undefined || null, () => html`N/A`],
                            [ServerKeysStatus.Active, () => html`<span style="color:green">Active</span>`],
                            [ServerKeysStatus.Revoked, () => html`<span style="color:red">Revoked</span>`],
                            [ServerKeysStatus.Deleted, () => html`<span style="color:darkgoldenrod">Deleted</span>`],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "created",
                title: "Added",
                align: "center",
                sortable: true,
                template: (row, col) => html`${new Date(row.created).toLocaleDateString()}`,
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
        const result = await this._serverKeysService.api.GetAllServerKeys(this._pageIndex, this._recordsPerPage, this._sortOrder);
        if (result.isOk) {
            this._data = result.value.serverKeys;
            this._totalRecordCount = result.value.totalRecordCount;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private addNewServerKey() {
        Router.go("/manage/admin/edit/server-key");
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete ServerKeys",
            body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} destination`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const serverKeyIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._serverKeysService.api.deleteAllAsync(serverKeyIds);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private filterByType(evt: any) {
        evt.stopPropagation();
        this._status = evt.currentTarget.value;
        this.loadData();
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>
                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                    <se-primary-button @click=${this.addNewServerKey} text="New ServerKey">New ServerKey</se-primary-button>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No server keys available."
                ></se-data-grid>
                <div style="overflow:hidden;display:flex;justify-content: center;">
                    <se-pagination
                        .recordsPerPage=${this._recordsPerPage}
                        .recordCount=${this._totalRecordCount}
                        @pagechanged=${this.onPageChanged}
                    ></se-pagination>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
