import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { ApiKey } from "../../models/api-key";
import { ApiKeysGridConfig } from "../components/api-keys/api-keys-grid.element";
import { SeApiKeysGridElement } from "../components/api-keys/api-keys-grid.element";
import './user-api-key-editor.element';
import '../components/cards/card.element';


@customElement("se-user-api-keys")
export class SeUserApiKeysElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _userService: UserService;

    @state() private _data: ApiKey[] = [];
    @query("se-api-keys-grid") private _grid: SeApiKeysGridElement;

    private _gridConfig: ApiKeysGridConfig;

    /**
     * Initializes the component and its dependencies.
     * Sets up the grid configuration with handlers for various actions.
     */
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._userService = container.resolve(UserService);

        this._gridConfig = {
            onEdit: (row) => Router.go(`/user/api-keys/${row.id}/edit`),
            onDelete: this.deleteKeyAsync.bind(this),
            onDeleteMany: this.deleteAllKeysAsync.bind(this),
            onRefresh: this.loadData.bind(this),
            onNew: () => Router.go('/user/api-keys/new')
        };
    }

    /**
     * Lifecycle callback when element is added to the DOM.
     * Resets the selected label and loads initial data.
     */
    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -4;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }

    /**
     * Router guard that checks if user is authenticated before entering the page.
     * @param location - The target router location
     * @param commands - Router commands for navigation control
     * @param router - Router instance
     * @returns Redirect command if user is not logged in
     */
    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
    }

    /**
     * Handles deletion of a single API key after user confirmation.
     * @param row - The API key row to be deleted
     */
    private async deleteKeyAsync(row: ApiKey & { selected: boolean }) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete API Key",
            body: `Are you sure you want to delete ${row.displayToken}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._userService.api.deleteUserApiKeyAsync(row.id);
            if (result.isOk) {
                await this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    /**
     * Handles bulk deletion of multiple API keys after user confirmation.
     * @param keyIds - Array of API key IDs to be deleted
     */
    private async deleteAllKeysAsync(keyIds: number[]) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete API Keys",
            body: `Are you sure you want to delete ${keyIds.length === 1 ? "this key" : `${keyIds.length} API keys`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._userService.api.deleteAllUserApiKeysAsync(keyIds);
            if (result.isOk) {
                await this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    /**
     * Fetches and updates the list of API keys from the server.
     * Updates the grid data if the grid component is available.
     */
    private async loadData() {
        const result = await this._userService.api.getUserApiKeysAsync();
        if (result.isOk) {
            this._data = result.value.userApiKeys;
            if (this._grid) {
                this._grid.data = this._data;
            }
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    /**
     * Renders the API keys grid component.
     * @returns Rendered template
     */
    render() {

        if (this._authService.isSE4Admin) {
            return html`
            <se-card simple
            >
                <div>Sequentum Administrators are not allowed to have API keys.</div>
            </se-card>
            `;
        }
        
        return html`
            <se-api-keys-grid .data=${this._data} .config=${this._gridConfig}></se-api-keys-grid>
        `;
    }

    static styles = css`
        :host {
            display: block;
            height: 100%;
        }
    `;
}
