import { Router } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import '../components/cards/card.element';
import '../components/cards/card-buttons.element';

@customElement("se-user-logout")
export class SeUserLogoutElement extends LitElement {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    private onLogout() {
        this._authService.logout();
        document.location = "/login";
    }

    render() {
        return html`
            <se-card simple>
                <div>Click the button below to log out of your account.</div>
            </se-card>
            <se-card-buttons>
                <se-primary-button 
                    text="Logout" 
                    @click="${this.onLogout}"
                ></se-primary-button>
            </se-card-buttons>
        `;
    }

    static styles = css`
        :host {
            display: block;
            height: 100%;
        }
    `;
} 